import { Box, Typography, Tabs, Tab, Divider, Link, Avatar, Button, IconButton, Menu, MenuItem } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import ProjectFloorsTab from './ProjectFloorsTab'
import { useRxQuery } from 'rxdb-hooks'
import { useProjectsCollection } from '../app/DBProvider'
import NotFoundPage from '@/components/NotFoundPage'
import Breadcrumbs from '../layout/Breadcrumbs'
import ProjectStatusChip from '../project/ProjectStatusChip'
import { useEffect, useState } from 'react'
import { ProjectStage } from '@/lib/schemas/ProjectSchema'
import ProjectEditModal from './ProjectEditModal'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ProjectReportsTab from './ProjectReportsTab'
import ProjectConnectionsTab from './ProjectConnectionsTab'
import ProjectPartsTab from './ProjectPartsTab'
import ProjectLaborTab from './ProjectLaborTab'
import ProjectProgressTab from './ProjectProgressTab'
import ProjectMembersTab from './ProjectMembersTab'
import ProjectFilesTab from './ProjectFilesTab'
import { User } from '@/lib/schemas/UserSchema'

const tabIds = ['plans', 'reports', 'connections', 'parts', 'labor', 'progress', 'users', 'files']

export default function ProjectPage() {
  const accountId = useParams<{ accountId: string }>().accountId as string
  const projectId = useParams<{ projectId: string }>().projectId as string
  const tabId = useParams<{ tabId: string }>().tabId
  const projectsCollection = useProjectsCollection()
  const [isPopulated, setIsPopulated] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))

  const { result: projects, isFetching } = useRxQuery(projectsCollection.findOne(projectId))
  const project = !isFetching && projects?.length > 0 ? projects[0] : undefined

  useEffect(() => {
    if (isFetching || !project) return
    const populateProject = async () => {
      const userField = project.stage === ProjectStage.design ? 'designerUserId' : 'projectManagerUserId'
      const user = await project.populate(userField)
      if (project.stage === ProjectStage.design) {
        ;(project as any).designerUser = user
      } else {
        ;(project as any).projectManagerUser = user
      }
      setIsPopulated(true)
    }
    populateProject()
  }, [isFetching, project])

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleEditClick = () => {
    setIsEditModalOpen(true)
    handleMenuClose()
  }

  function handleCloseModal(): void {
    setIsEditModalOpen(false)
  }

  if (isFetching || !isPopulated) return <></>
  if (!project) return <NotFoundPage />

  // Determine the tab index based on the current route
  const tabIndex = tabIds.indexOf(tabId ?? 'plans')

  const displayUser = (
    project.stage === 'design' ? (project as any).designerUser : (project as any).projectManagerUser
  ) as User

  return (
    <>
      <Breadcrumbs />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
            {project.name}
            <ProjectStatusChip
              status={project.stage === 'design' ? project.designStatus : project.installationStatus}
              sx={{ ml: 2 }}
              size="small"
            />
          </Typography>
          {project.address && (
            <Typography variant="body2" color="textSecondary">
              {project.address}
            </Typography>
          )}
          {(project.startDate || project.completionDate) && (
            <Typography variant="body2" color="textSecondary">
              {project.startDate &&
                !project.completionDate &&
                `Start Date: ${new Date(project.startDate).toLocaleDateString()}`}
              {project.startDate &&
                project.completionDate &&
                `${new Date(project.startDate).toLocaleDateString()} - ${new Date(project.completionDate).toLocaleDateString()}`}
              {!project.startDate &&
                project.completionDate &&
                `Completion Date: ${new Date(project.completionDate).toLocaleDateString()}`}
            </Typography>
          )}
          {displayUser && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Avatar src={displayUser.profileImagePath} sx={{ width: 24, height: 24, mr: 1 }} />
              <Typography variant="body2" color="textSecondary">
                {displayUser.name}
              </Typography>
            </Box>
          )}
        </Box>
        {isPhone ? (
          <>
            <IconButton onClick={handleMenuClick} sx={{ alignSelf: 'flex-start' }}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
              <MenuItem onClick={handleEditClick}>Edit Project</MenuItem>
            </Menu>
          </>
        ) : (
          <Button variant="outlined" color="primary" onClick={handleEditClick} sx={{ alignSelf: 'flex-start' }}>
            Edit
          </Button>
        )}
      </Box>
      <Box sx={{ overflowX: 'auto', width: '100%' }}>
        <Tabs value={tabIndex} aria-label="projects tabs" variant="scrollable">
          <Tab label="Plans" component={Link} href={`/accounts/${accountId}/projects/${projectId}`} />
          <Tab label="Reports" component={Link} href={`/accounts/${accountId}/projects/${projectId}/reports`} />
          <Tab label="Connections" component={Link} href={`/accounts/${accountId}/projects/${projectId}/connections`} />
          <Tab label="Parts" component={Link} href={`/accounts/${accountId}/projects/${projectId}/parts`} />
          <Tab label="Labor" component={Link} href={`/accounts/${accountId}/projects/${projectId}/labor`} />
          <Tab label="Progress" component={Link} href={`/accounts/${accountId}/projects/${projectId}/progress`} />
          <Tab label="Users" component={Link} href={`/accounts/${accountId}/projects/${projectId}/users`} />
          <Tab label="Files" component={Link} href={`/accounts/${accountId}/projects/${projectId}/files`} />
        </Tabs>
      </Box>
      <Divider sx={{ width: '100%', mb: 2 }} />
      {tabIndex === 0 && <ProjectFloorsTab project={project} />}
      {tabIndex === 1 && <ProjectReportsTab />}
      {tabIndex === 2 && <ProjectConnectionsTab />}
      {tabIndex === 3 && <ProjectPartsTab />}
      {tabIndex === 4 && <ProjectLaborTab />}
      {tabIndex === 5 && <ProjectProgressTab />}
      {tabIndex === 6 && <ProjectMembersTab />}
      {tabIndex === 7 && <ProjectFilesTab />}
      <ProjectEditModal open={isEditModalOpen} onClose={handleCloseModal} project={project} />
    </>
  )
}
