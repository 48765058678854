import { Box } from '@mui/material'
import PageHeader from '../lib/PageHeader'
import Breadcrumbs from '../layout/Breadcrumbs'

export default function AccountProjectDevicesPage() {
  return (
    <>
      <Breadcrumbs />
      <Box sx={{ mb: 2 }}>
        <PageHeader title="Account Project Devices" description="" />
      </Box>
      <p>Todo</p>
    </>
  )
}
