import { Box, Typography, Tabs, Tab, Divider, Link, Button, IconButton, Menu, MenuItem } from '@mui/material'
import { useParams, useLocation } from 'react-router-dom'
import BuildingFloorsTab from './BuildingFloorsTab'
import { useRxQuery } from 'rxdb-hooks'
import { useBuildingsCollection } from '../app/DBProvider'
import NotFoundPage from '@/components/NotFoundPage'
import Breadcrumbs from '../layout/Breadcrumbs'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import BuildingEditModal from './BuildingEditModal'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export default function BuildingPage() {
  const accountId = useParams<{ accountId: string }>().accountId as string
  const buildingId = useParams<{ buildingId: string }>().buildingId as string
  const location = useLocation()
  const buildingsCollection = useBuildingsCollection()

  const { result: buildings, isFetching } = useRxQuery(buildingsCollection.findOne(buildingId))

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleEditClick = () => {
    setIsEditModalOpen(true)
    handleMenuClose()
  }

  function handleCloseModal(): void {
    setIsEditModalOpen(false)
  }

  if (isFetching) return <></>
  if (buildings.length === 0) return <NotFoundPage />
  const building = buildings[0]

  // Determine the tab index based on the current route
  const tabIndex = location.pathname.endsWith('/members') ? 1 : 0

  return (
    <>
      <Breadcrumbs />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h5">{building.name}</Typography>
          {building.address && (
            <Typography variant="body2" color="textSecondary">
              {building.address}
            </Typography>
          )}
        </Box>
        {isPhone ? (
          <>
            <IconButton onClick={handleMenuClick} sx={{ alignSelf: 'flex-start' }}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
              <MenuItem onClick={handleEditClick}>Edit Building</MenuItem>
            </Menu>
          </>
        ) : (
          <Button variant="outlined" color="primary" onClick={handleEditClick} sx={{ alignSelf: 'flex-start' }}>
            Edit
          </Button>
        )}
      </Box>
      <Tabs value={tabIndex} aria-label="buildings tabs">
        <Tab label="Floors" component={Link} href={`/accounts/${accountId}/buildings/${buildingId}`} />
      </Tabs>
      <Divider sx={{ width: '100%', mb: 2 }} />
      {tabIndex === 0 && <BuildingFloorsTab />}
      <BuildingEditModal open={isEditModalOpen} onClose={handleCloseModal} building={building} />
    </>
  )
}
