import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Link,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@mui/material'
import {
  DataGridPremium,
  gridClasses,
  GridColDef,
  GridPagination,
  gridPaginationModelSelector,
  gridRowCountSelector,
  GridSlotsComponent,
  GridSlotsComponentsProps,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium' // Updated import
import NoRowsOverlay from '../lib/NoRowsOverlay'
import { useNavigate, useParams } from 'react-router-dom'
import { useState, useMemo, useEffect } from 'react'
import { useRxQuery } from 'rxdb-hooks'
import { useProjectFloorsCollection } from '../app/DBProvider'
import { RxDocument } from 'rxdb'
import { faPlus, faEllipsisV, faClipboardCheck, faTrash } from '@fortawesome/free-solid-svg-icons'
import NewFloorModal from './NewProjectFloorModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProjectFloor } from '@/lib/schemas/ProjectFloorSchema'
import { Project } from '@/lib/schemas/ProjectSchema'
import { Floor } from '@/lib/schemas/FloorSchema'

interface ProjectFloorsTableProps {
  project: Project
}

function NoFloorsOverlay() {
  return <NoRowsOverlay message="No floors" />
}

export default function ProjectFloorsTab({ project }: Readonly<ProjectFloorsTableProps>) {
  const accountId = useParams<{ accountId: string }>().accountId as string
  const projectId = project.id
  const projectFloorsCollection = useProjectFloorsCollection()
  const [isPopulated, setIsPopulated] = useState(false)
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const isTablet = useMediaQuery(theme.breakpoints.up('md'))
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedFloor, setSelectedFloor] = useState<ProjectFloor | null>(null)
  const apiRef = useGridApiRef()

  const rxQuery = useMemo(() => {
    const query = projectFloorsCollection.find().where('projectId').equals(projectId).sort('projectId')

    return query
  }, [projectFloorsCollection, projectId])

  const { result: projectFloors, isFetching } = useRxQuery(rxQuery)

  useEffect(() => {
    if (isFetching || !projectFloors) return
    const populateFloors = async () => {
      for (const projectFloor of projectFloors) {
        const floor = (await projectFloor.populate('floorId')) as RxDocument<Floor>
        ;(projectFloor as any).floorName = floor.name
        ;(projectFloor as any).floorPlanImagePath = floor.planImagePath
        const building = await floor.populate('buildingId')
        ;(projectFloor as any).buildingName = building.name
      }
      setIsPopulated(true)
    }
    populateFloors()
  }, [isFetching, projectFloors])

  const handleRowClick = (params: any) => {
    if (isPhone) {
      navigate(`/accounts/${params.row.accountId}/projects/${projectId}/floors/${params.row.id}`)
    }
  }

  const handleDeleteRow = (projectFloor: ProjectFloor) => {
    setSelectedFloor(projectFloor)
    setDeleteDialogOpen(true)
  }

  const handleConfirmDelete = () => {
    if (selectedFloor) {
      // Implement the delete functionality here
      console.log(`Delete projectFloor with id: ${selectedFloor.id}`)
    }
    setDeleteDialogOpen(false)
    setSelectedFloor(null)
  }

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
    setSelectedFloor(null)
  }

  const slots: Partial<GridSlotsComponent> = {
    noRowsOverlay: NoFloorsOverlay,
    toolbar: CustomToolbar,
    columnHeaders: isPhone ? () => null : undefined, // Hide column headers only on small screens
    pagination: CustomPagination,
  }

  const slotProps: GridSlotsComponentsProps = {
    toolbar: {
      showQuickFilter: true,
    },
  }

  const columns: GridColDef<RxDocument<ProjectFloor>>[] = [
    {
      field: 'planImagePath',
      headerName: 'Thumbnail',
      flex: 1,
      renderCell: (params) => {
        const projectFloor = params.row as any
        return (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img
              src={`${import.meta.env.VITE_IMGIX_BASE_URL}/${projectFloor.floorPlanImagePath}`}
              alt={projectFloor.floorName}
              style={{ width: '75px', height: '75px', objectFit: 'cover' }}
            />
          </Box>
        )
      },
    },
    {
      field: 'buildingName',
      headerName: 'Building',
      flex: 2,
      renderCell: (params) => {
        return <Typography variant="body2">{params.value}</Typography>
      },
    },
    {
      field: 'name',
      headerName: 'Plan Name',
      flex: 2,
      renderCell: (params) => {
        return (
          <Box sx={{ width: '100%' }}>
            <Link href={`/accounts/${accountId}/projects/${projectId}/floors/${params.row.id}`} variant="body1">
              {(params.row as any).floorName}
            </Link>
            {isPhone && (
              <Typography variant="body2" color="textSecondary">
                6 Plans | 48 Devices | 23 Tasks | 55 Budgeted Hours
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <FontAwesomeIcon icon={faClipboardCheck} style={{ marginRight: '8px' }} />
                  <Typography variant="body2">6 Punch List</Typography>
                </Box>
              </Typography>
            )}
          </Box>
        )
      },
    },
  ]

  if (isTablet) {
    columns.push(
      {
        field: 'plans',
        headerName: 'Plans',
        flex: 1,
        renderCell: () => <Typography variant="body2">6</Typography>,
      },
      {
        field: 'devices',
        headerName: 'Devices',
        flex: 1,
        renderCell: () => <Typography variant="body2">48</Typography>,
      },
      {
        field: 'tasks',
        headerName: 'Tasks',
        flex: 1,
        renderCell: () => <Typography variant="body2">23</Typography>,
      },
      {
        field: 'bugetedHours',
        headerName: 'Budgeted Hours',
        flex: 2,
        renderCell: () => (
          <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={100} />
            <Typography variant="caption" color="textSecondary" sx={{ whiteSpace: 'nowrap' }}>
              100% of 54 Budgeted Hours Completed
            </Typography>
          </Box>
        ),
      },
      {
        field: 'punchList',
        headerName: 'Punch List',
        flex: 1,
        renderCell: () => (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={faClipboardCheck} style={{ marginRight: '8px' }} />
            <Typography variant="body2">6</Typography>
          </Box>
        ),
      },
      {
        field: 'delete',
        headerName: '',
        flex: 0,
        renderCell: (params) => (
          <IconButton onClick={() => handleDeleteRow(params.row)} size="small" sx={{ color: theme.palette.error.main }}>
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        ),
      },
    )
  }

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      // rowGrouping: {
      //   model: ['buildingName'],
      // },
      sorting: {
        sortModel: [{ field: 'name', sort: 'asc' as GridSortDirection }], // Set initial sort
      },
      pagination: { paginationModel: { pageSize: 25 } },
    },
  })

  return (
    <>
      <DataGridPremium
        columns={columns}
        rows={projectFloors} // Use floors directly
        getRowId={(row) => row.id} // Add getRowId function
        apiRef={apiRef}
        loading={!isPopulated}
        initialState={initialState}
        slots={slots}
        slotProps={slotProps}
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableColumnResize
        onRowClick={handleRowClick} // Add onRowClick handler only for phone screens
        getRowHeight={() => 'auto'} // Set row height to auto
        pagination
        pageSizeOptions={[25, 50, 100]}
        sx={{
          // this removes the drag handles
          [`& .${gridClasses.columnSeparator}`]: {
            [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
              display: 'none',
            },
          },
          // this removes the cell focus
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}`]: {
            backgroundImage: 'var(--Paper-overlay)',
          },
          border: 'none',
          [`& .${gridClasses.cell}`]: {
            display: 'flex',
            alignItems: 'center', // Center values vertically
            paddingTop: '16px', // Add padding to the top of each row
            paddingBottom: '16px', // Add padding to the bottom of each row
          },
          [`& .${gridClasses.row}`]: {
            cursor: { xs: 'pointer', md: 'default' }, // Add cursor pointer to rows only on phone
          },
          ...(isPhone && {
            [`& .MuiDataGrid-footerContainer`]: {
              borderTop: 'none', // Remove top border
            },
            marginLeft: -3, // Add negative left padding for small screens
            marginRight: -3, // Add negative right padding for small screens
            [`& .${gridClasses.cell}`]: {
              paddingX: 3, // Add positive right padding for small screens
              paddingY: 2, // Add positive top and bottom padding for small screens
            },
          }),
        }}
      />
      <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Remove Edited Plan From Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove {(selectedFloor as any)?.floor.name} including 25 unpublished Devices from{' '}
            {project.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} variant="contained" color="error" autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function CustomToolbar() {
  const accountId = useParams<{ accountId: string }>().accountId as string
  const siteId = useParams<{ siteId: string }>().siteId as string
  const buildingId = useParams<{ buildingId: string }>().buildingId as string
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuNewBuildingClick = () => {
    handleMenuClose()
    handleOpenModal()
  }

  const handleCreateFloor = (floor: any) => {
    // Navigate to the newly created building's page
    navigate(`/accounts/${floor.accountId}/sites/${floor.siteId}/buildings/${floor.id}/floors/${floor.id}`)
  }

  return (
    <GridToolbarContainer
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        ...(isPhone && {
          paddingLeft: 3,
          paddingRight: 3,
        }),
      }}
    >
      <GridToolbarQuickFilter />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          size="small"
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={handleOpenModal}
          sx={{
            display: { xs: 'none', md: 'inline-flex' },
            whiteSpace: 'nowrap',
            borderColor: theme.palette.text.secondary,
            color: theme.palette.text.secondary,
          }}
        >
          New Plan
        </Button>
        <Box sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
          <IconButton onClick={handleMenuOpen}>
            <FontAwesomeIcon icon={faEllipsisV} />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuNewBuildingClick}>New Plan</MenuItem>
          </Menu>
        </Box>
      </Box>
      <NewFloorModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onCreate={handleCreateFloor}
        accountId={accountId}
        siteId={siteId}
        buildingId={buildingId}
      />
    </GridToolbarContainer>
  )
}

function CustomPagination(props: any) {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const apiRef = useGridApiContext()
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector)
  const rowCount: number = useGridSelector(apiRef, gridRowCountSelector)

  const handleLoadMore = () => {
    apiRef.current.setPageSize(paginationModel.pageSize + 25)
  }

  if (isPhone) {
    if (paginationModel.pageSize >= rowCount) {
      return null
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          my: 2,
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          width: 'fit-content',
          backgroundColor: 'white', // Ensure background color to avoid overlap issues
          zIndex: 1, // Ensure it stays above other content
        }}
      >
        <Button variant="outlined" color="primary" onClick={handleLoadMore} sx={{ textAlign: 'center' }}>
          Load More...
        </Button>
        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
          Showing {paginationModel.pageSize} of {rowCount}
        </Typography>
      </Box>
    )
  } else {
    return <GridPagination {...props} />
  }
}
