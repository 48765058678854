import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite'
import { addRxPlugin, createRxDatabase, RxCollection, RxDatabase, RxSchema, RxStorage } from 'rxdb'
import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb'
import { getRxStorageSQLite, getSQLiteBasicsCapacitor } from 'rxdb-premium/plugins/storage-sqlite'
import { disableWarnings, RxDBDevModePlugin } from 'rxdb/plugins/dev-mode'
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election'
import { RxDBLocalDocumentsPlugin } from 'rxdb/plugins/local-documents'
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder'
import { replicateFirestore, RxFirestoreReplicationState } from 'rxdb/plugins/replication-firestore'

import { FronteggAuthState } from '@/components/app/FronteggUserProvider'
import { Capacitor } from '@capacitor/core'
import {
  collection,
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  QueryFieldFilterConstraint,
  Timestamp,
  where,
} from 'firebase/firestore'
import * as _ from 'lodash'
import { setPremiumFlag } from 'rxdb-premium/plugins/shared'
import { filter, firstValueFrom, mergeMap } from 'rxjs'
import { AccountMemberCollection, accountMemberSchema } from './schemas/AccountMemberSchema'
import { AccountCollection, accountSchema } from './schemas/AccountSchema'
import { BuildingCollection, buildingSchema } from './schemas/BuildingSchema'
import { DeviceImageCollection, deviceImageSchema } from './schemas/DeviceImageSchema'
import { DeviceCollection, deviceSchema } from './schemas/DeviceSchema'
import { DeviceVersionCollection, deviceVersionSchema } from './schemas/DeviceVersionSchema'
import { EquipmentTypeCategoryCollection, equipmentTypeCategorySchema } from './schemas/EquipmentTypeCategorySchema'
import { EquipmentTypeCollection, equipmentTypeSchema } from './schemas/EquipmentTypeSchema'
import { FavoriteDeviceCollection, favoriteDeviceSchema } from './schemas/FavoriteDeviceSchema'
import { FavoriteCollection, favoriteSchema } from './schemas/FavoriteSchema'
import { FavoriteTaskCollection, favoriteTaskSchema } from './schemas/FavoriteTaskSchema'
import { FloorCollection, floorSchema } from './schemas/FloorSchema'
import { LocationCollection, locationSchema } from './schemas/LocationSchema'
import { OrgMemberCollection, orgMemberSchema } from './schemas/OrgMemberSchema'
import { OrgCollection, orgSchema } from './schemas/OrgSchema'
import { ProjectFileAttachmentCollection, projectFileAttachmentSchema } from './schemas/ProjectFileAttachmentSchema'
import { ProjectFloorCollection, projectFloorSchema } from './schemas/ProjectFloorSchema'
import { ProjectMemberCollection, projectMemberSchema } from './schemas/ProjectMemberSchema'
import { ProjectCollection, projectSchema } from './schemas/ProjectSchema'
import { ServiceTicketCommentCollection, serviceTicketCommentSchema } from './schemas/ServiceTicketCommentSchema'
import {
  ServiceTicketFileAttachmentCollection,
  serviceTicketFileAttachmentSchema,
} from './schemas/ServiceTicketFileAttachmentSchema'
import { ServiceTicketCollection, serviceTicketSchema } from './schemas/ServiceTicketSchema'
import { ServiceTicketWatcherCollection, serviceTicketWatcherSchema } from './schemas/ServiceTicketWatcherSchema'
import { SiteFileAttachmentCollection, siteFileAttachmentSchema } from './schemas/SiteFileAttachmentSchema'
import { SiteMemberCollection, siteMemberSchema } from './schemas/SiteMemberSchema'
import { SiteCollection, siteSchema } from './schemas/SiteSchema'
import { TaskImageCollection, taskImageSchema } from './schemas/TaskImageSchema'
import { TaskCollection, taskSchema } from './schemas/TaskSchema'
import { TaskTypeCollection, taskTypeSchema } from './schemas/TaskTypeSchema'
import { UserAccountPreferencesCollection, userAccountPreferencesSchema } from './schemas/UserAccountPreferencesSchema'
import { UserDeviceTokenCollection, userDeviceTokenSchema } from './schemas/UserDeviceTokenSchema'
import { UserFloorPreferencesCollection, userFloorPreferencesSchema } from './schemas/UserFloorPreferencesSchema'
import { UserCollection, userSchema } from './schemas/UserSchema'
import { wrappedValidateAjvStorage } from './validate-ajv-so'
import { groupSchema } from './schemas/GroupSchema'
import { groupMemberSchema } from './schemas/GroupMemberSchema'

// we paid for premium RxDb.  so turn off warnings a restrictions
setPremiumFlag()

// don't show the dev mode warning in dev because we expect them
if (process.env.NODE_ENV === 'development') {
  disableWarnings()
  addRxPlugin(RxDBDevModePlugin)
}

addRxPlugin(RxDBLocalDocumentsPlugin)
addRxPlugin(RxDBLeaderElectionPlugin)
addRxPlugin(RxDBQueryBuilderPlugin)

export type SiteOwlCollections = {
  buildings: BuildingCollection
  account_members: AccountMemberCollection
  accounts: AccountCollection
  device_images: DeviceImageCollection
  devices: DeviceCollection
  device_versions: DeviceVersionCollection
  equipment_type_categories: EquipmentTypeCategoryCollection
  equipment_types: EquipmentTypeCollection
  favorite_devices: FavoriteDeviceCollection
  favorite_tasks: FavoriteTaskCollection
  favorites: FavoriteCollection
  floors: FloorCollection
  locations: LocationCollection
  org_members: OrgMemberCollection
  orgs: OrgCollection
  project_files: ProjectFileAttachmentCollection
  project_floors: ProjectFloorCollection
  project_members: ProjectMemberCollection
  projects: ProjectCollection
  service_ticket_comments: ServiceTicketCommentCollection
  service_ticket_file_attachments: ServiceTicketFileAttachmentCollection
  service_ticket_watchers: ServiceTicketWatcherCollection
  service_tickets: ServiceTicketCollection
  site_files: SiteFileAttachmentCollection
  site_members: SiteMemberCollection
  sites: SiteCollection
  task_images: TaskImageCollection
  task_types: TaskTypeCollection
  tasks: TaskCollection
  users: UserCollection
  user_account_preferences: UserAccountPreferencesCollection
  user_device_tokens: UserDeviceTokenCollection
  user_floor_preferences: UserFloorPreferencesCollection
}

export type SiteOwlDatabase = RxDatabase<SiteOwlCollections>

export async function createDb(): Promise<SiteOwlDatabase> {
  let storage: RxStorage<any, any>
  if (Capacitor.isNativePlatform()) {
    // if in a native app, use the sqlite storage
    console.log('initializing the sqlite storage')
    const sqlite = new SQLiteConnection(CapacitorSQLite)
    storage = getRxStorageSQLite({
      sqliteBasics: getSQLiteBasicsCapacitor(sqlite, Capacitor),
    })
  } else {
    console.log('initializing the indexeddb storage')
    // otherwise use the IndexDB storage for the web browser
    storage = getRxStorageIndexedDB()
  }

  // if we are in the dev environment, wrap the storage with the validateAjvStorage
  // for additional runntime validation and checks
  if (import.meta.env.VITE_NODE_ENV === 'development') {
    console.log('wrapping storage with validateAjvStorage because we are in development')
    storage = wrappedValidateAjvStorage({
      storage,
    })
  }

  console.log('creating the database')
  const db: SiteOwlDatabase = await createRxDatabase<SiteOwlCollections>({
    name: 'siteowlrxdb',
    storage,
    multiInstance: true,
    eventReduce: true,
    ignoreDuplicate: process.env.NODE_ENV === 'development',
  })
  return db
}

export async function initializeDb(db: SiteOwlDatabase) {
  await db.addCollections({
    buildings: {
      schema: buildingSchema,
      localDocuments: true,
    },
    account_members: {
      schema: accountMemberSchema,
      localDocuments: true,
    },
    accounts: {
      schema: accountSchema,
      localDocuments: true,
    },
    device_images: {
      schema: deviceImageSchema,
      localDocuments: true,
    },
    devices: {
      schema: deviceSchema,
      localDocuments: true,
    },
    device_versions: {
      schema: deviceVersionSchema,
      localDocuments: true,
    },
    equipment_type_categories: {
      schema: equipmentTypeCategorySchema,
      localDocuments: true,
    },
    equipment_types: {
      schema: equipmentTypeSchema,
      localDocuments: true,
    },
    favorite_devices: {
      schema: favoriteDeviceSchema,
      localDocuments: true,
    },
    favorite_tasks: {
      schema: favoriteTaskSchema,
      localDocuments: true,
    },
    favorites: {
      schema: favoriteSchema,
      localDocuments: true,
    },
    floors: {
      schema: floorSchema,
      localDocuments: true,
    },
    groups: {
      schema: groupSchema,
      localDocuments: true,
    },
    group_members: {
      schema: groupMemberSchema,
      localDocuments: true,
    },
    locations: {
      schema: locationSchema,
      localDocuments: true,
    },
    org_members: {
      schema: orgMemberSchema,
      localDocuments: true,
    },
    orgs: {
      schema: orgSchema,
      localDocuments: true,
    },
    project_files: {
      schema: projectFileAttachmentSchema,
      localDocuments: true,
    },
    project_floors: {
      schema: projectFloorSchema,
      localDocuments: true,
    },
    project_members: {
      schema: projectMemberSchema,
      localDocuments: true,
    },
    projects: {
      schema: projectSchema,
      localDocuments: true,
    },
    service_ticket_comments: {
      schema: serviceTicketCommentSchema,
      localDocuments: true,
    },
    service_ticket_file_attachments: {
      schema: serviceTicketFileAttachmentSchema,
      localDocuments: true,
    },
    service_ticket_watchers: {
      schema: serviceTicketWatcherSchema,
      localDocuments: true,
    },
    service_tickets: {
      schema: serviceTicketSchema,
      localDocuments: true,
    },
    site_files: {
      schema: siteFileAttachmentSchema,
      localDocuments: true,
    },
    site_members: {
      schema: siteMemberSchema,
      localDocuments: true,
    },
    sites: {
      schema: siteSchema,
      localDocuments: true,
    },
    task_images: {
      schema: taskImageSchema,
      localDocuments: true,
    },
    task_types: {
      schema: taskTypeSchema,
      localDocuments: true,
    },
    tasks: {
      schema: taskSchema,
      localDocuments: true,
    },
    users: {
      schema: userSchema,
      localDocuments: true,
    },
    user_account_preferences: {
      schema: userAccountPreferencesSchema,
      localDocuments: true,
    },
    user_device_tokens: {
      schema: userDeviceTokenSchema,
      localDocuments: true,
    },
    user_floor_preferences: {
      schema: userFloorPreferencesSchema,
      localDocuments: true,
    },
  })
}

// use the JSON schema to recursively find all the defined date fields and convert them from strings to Date()
const convertDates = (data: any, schema: any): any => {
  if (schema.type === 'object' && schema.properties) {
    return _.mapValues(data, (value, key) => {
      if (schema.properties[key]) {
        return convertDates(value, schema.properties[key])
      }
      return value
    })
  } else if (schema.type === 'array' && schema.items) {
    return data.map((item: any) => convertDates(item, schema.items))
  } else if (schema.type === 'string' && (schema.format === 'date' || schema.format === 'date-time')) {
    return new Date(data)
  }
  return data
}

// look for all Firestore Timestamp fields and convert them to string ISO 8601 dates
const convertToISOStrings = (data: Record<string, any>): any => {
  if (_.isObject(data) && !_.isDate(data)) {
    return _.mapValues(data, (value, key) => {
      if (key === 'serverTimestamp') return value // don't modify the serverTimestamp
      if (value instanceof Timestamp) {
        return value.toDate().toISOString()
      } else if (_.isArray(value)) {
        return value.map((item) => convertToISOStrings(item))
      } else if (_.isObject(value)) {
        return convertToISOStrings(value)
      }
      return value
    })
  }
  return data
}

const converter = <T>(schema: RxSchema<T>) => {
  return {
    toFirestore: (data: T): DocumentData => {
      // now replace date strings with Date()
      return convertDates(data, schema) as DocumentData
    },
    fromFirestore: (snap: QueryDocumentSnapshot): T => {
      // map all Firestore Timestamps back to string dates
      const data = convertToISOStrings(snap.data())
      return data as T
    },
  }
}

async function syncCollection(
  db: SiteOwlDatabase,
  firestore: Firestore,
  collectionName: string,
  pullFilter?: QueryFieldFilterConstraint | QueryFieldFilterConstraint[] | undefined,
): Promise<RxFirestoreReplicationState<any>> {
  const rxdbCollection = _.get(db, collectionName) as RxCollection<any>
  if (rxdbCollection === undefined) console.log('!!!!!!', collectionName)
  const schema = rxdbCollection.schema

  try {
    await rxdbCollection.insertLocal('last-in-sync', { time: 0 })
  } catch {
    // do nothing if it already exists
  }

  const replicationState = replicateFirestore({
    replicationIdentifier: `https://firestore.googleapis.com/${import.meta.env.VITE_FIREBASE_PROJECT_ID}`,
    collection: rxdbCollection,
    firestore: {
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      database: firestore,
      collection: collection(firestore, collectionName).withConverter(converter(schema)),
    },
    pull: {
      ...(pullFilter && { filter: pullFilter }),
    },
    push: {},
    live: true,
    waitForLeadership: true,
    autoStart: false,
    serverTimestampField: 'serverTimestamp',
  })

  replicationState.received$.subscribe((doc) => console.log(`received ${collectionName}`, doc))
  replicationState.sent$.subscribe((doc) => console.log('sent', doc))
  replicationState.error$.subscribe((error) => console.log('error', collectionName, error))
  replicationState.canceled$.subscribe((bool) => console.log('canceled', bool))
  replicationState.active$.subscribe((bool) => console.log(`active ${collectionName}`, bool))
  replicationState.active$
    .pipe(
      mergeMap(async () => {
        await replicationState.awaitInSync()
        await rxdbCollection.upsertLocal('last-in-sync', { time: Date.now() })
      }),
    )
    .subscribe()

  return replicationState
}

export async function initializeSync(
  db: SiteOwlDatabase,
  firestore: Firestore,
  syncEnabled: boolean,
  fronteggAuthState: FronteggAuthState,
): Promise<RxFirestoreReplicationState<any>[]> {
  console.log('syncing users')
  const userReplicationState = await syncCollection(db, firestore, 'users')

  console.log('syncing orgs', fronteggAuthState.fronteggTenantIds)
  const orgReplicationState = await syncCollection(db, firestore, 'orgs', [
    where('fronteggId', 'in', fronteggAuthState.fronteggTenantIds || []),
  ])

  // wait for user and org sync to finish
  if (syncEnabled) {
    await userReplicationState.start()
    await orgReplicationState.start()
    console.log('waiting for org and user sync to finish')
    const lastSyncDuration = 1000 * 60
    await Promise.all(
      [userReplicationState, orgReplicationState].map((state) =>
        firstValueFrom(
          state.collection
            .getLocal$('last-in-sync')
            .pipe(filter((d: any) => d.get('time') > Date.now() - lastSyncDuration)),
        ),
      ),
    )
  }

  // get the userId from the users collection
  const userId = await db.users
    .findOne({
      selector: {
        fronteggUserId: fronteggAuthState.fronteggUserId,
      },
    })
    .exec()
    .then((user) => user?.id)

  const userCollections = ['user_account_preferences', 'user_floor_preferences']
  const replicationStatePromises = _.map(
    userCollections,
    async (c: string): Promise<RxFirestoreReplicationState<any>> => {
      console.log(`sycing ${c}`)
      return syncCollection(db, firestore, c, [where('userId', '==', userId)])
    },
  )

  // get the orgId from the orgs collection
  const orgIds = await db.orgs
    .find({
      selector: {
        fronteggId: {
          $in: fronteggAuthState.fronteggTenantIds || [],
        },
      },
    })
    .exec()
    .then((orgs) => orgs.map((org) => org.id))
  console.log('orgIds', orgIds)

  const collectionsWithOrgId = [
    'account_members',
    'accounts',
    'buildings',
    'favorite_devices',
    'favorite_tasks',
    'favorites',
    'floors',
    'groups',
    'group_members',
    'org_members',
    'project_files',
    'project_floors',
    'project_members',
    'projects',
    'service_ticket_comments',
    'service_ticket_file_attachments',
    'service_ticket_watchers',
    'service_tickets',
    'site_files',
    'site_members',
    'sites',
    'task_types',
  ]
  // Sync the other collections
  replicationStatePromises.push(
    ..._.map(collectionsWithOrgId, async (c: string): Promise<RxFirestoreReplicationState<any>> => {
      console.log(`sycing ${c}`)
      return syncCollection(db, firestore, c, [where('orgId', 'in', orgIds)])
    }),
  )

  const globalCollections = ['equipment_type_categories', 'equipment_types', 'task_types']
  replicationStatePromises.push(
    ..._.map(globalCollections, async (c: string): Promise<RxFirestoreReplicationState<any>> => {
      console.log(`sycing ${c}`)
      return syncCollection(db, firestore, c)
    }),
  )

  return await Promise.all(replicationStatePromises)
}
