import NotFoundPage from '@/components/NotFoundPage'
import { Tab, Tabs, Box, Link, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useProjectFloorsCollection } from '../app/DBProvider'
import ProjectFloorInfoTab from './ProjectFloorInfoTab'

const tabValues = ['info']

export default function ProjectFloorPage() {
  const { projectFloorId, tabId = 'info' } = useParams()
  const tabIndex = tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0
  const projectFloorsCollection = useProjectFloorsCollection()

  const { result: projectFloors, isFetching } = useRxQuery(projectFloorsCollection.findOne(projectFloorId), {
    json: true,
  })

  if (isFetching) return <></>

  const projectFloor = projectFloors?.[0]
  if (!projectFloor) return <NotFoundPage />

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {projectFloor.floorId}
      </Typography>
      <Tabs value={tabIndex} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="Info" component={Link} href={`/project-floors/${projectFloorId}`} />
      </Tabs>
      <Box sx={{ p: 2 }}>{tabIndex === 0 && <ProjectFloorInfoTab projectFloor={projectFloor} />}</Box>
    </>
  )
}
