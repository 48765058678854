import { useRxQuery } from 'rxdb-hooks'
import { useTasksCollection } from '../app/DBProvider'
import { Floor } from '@/lib/schemas/FloorSchema'
import { Task } from '@/lib/schemas/TaskSchema'
import { Table, TableBody, TableCell, TableHead, TableRow, Link } from '@mui/material'

export default function FloorTasksTab({ floor }: Readonly<{ floor: Floor }>) {
  const tasksCollection = useTasksCollection()

  const { result: tasks, isFetching } = useRxQuery(tasksCollection.find({ selector: { floorId: floor.id } }), {
    json: true,
  })

  if (isFetching) return <></>

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Tasks</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks?.map((task: Task) => {
          return (
            <TableRow key={task.id}>
              <TableCell>
                <Link href={`tasks/${task.id}`}>{task.name}</Link>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
