import { initializeSync } from '@/lib/rxdb'
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { RxFirestoreReplicationState } from 'rxdb/dist/types/plugins/replication-firestore'
import { filter, firstValueFrom } from 'rxjs'
import { useFirebaseAuthState } from './FirebaseUserProvider'
import { useFronteggAuthState } from './FronteggUserProvider'
import { useDBState } from './DBProvider'
import { useFirestore } from './FirebaseProvider'

type DBSyncState = {
  syncEnabled: boolean
  setSyncEnabled: (enabled: boolean) => void
  syncIsLoading: boolean
  initialSyncComplete: boolean
}

export const DBSyncContext = createContext<DBSyncState>({
  syncEnabled: true,
  setSyncEnabled: () => {},
  syncIsLoading: true,
  initialSyncComplete: false,
})

export default function DBProvider({
  defaultSyncEnabled = true,
  defaultSyncIsLoading = true,
  children,
}: Readonly<{
  defaultSyncEnabled?: boolean
  defaultSyncIsLoading?: boolean
  children: React.ReactNode
}>) {
  const { db } = useDBState()
  const [cookies, setCookie] = useCookies(['syncEnabled']) // Initialize react-cookie
  console.log(`DBSyncProvider: cookies=${JSON.stringify(cookies)}`)
  const [syncEnabled, setSyncEnabled] = useState(
    cookies.syncEnabled !== undefined ? cookies.syncEnabled : defaultSyncEnabled,
  )
  console.log(`DBSyncProvider: syncEnabled=${syncEnabled}`)
  const [syncIsLoading, setSyncIsLoading] = useState(defaultSyncIsLoading)
  const replicationStatesRef = useRef<RxFirestoreReplicationState<any>[]>([])
  const [initialSyncComplete, setInitialSyncComplete] = useState(false)
  const firestore = useFirestore()
  const {
    firebaseAuth: { firebaseUserId, firebaseAuthIsLoading },
  } = useFirebaseAuthState()
  const fronteggAuthState = useFronteggAuthState()

  useEffect(() => {
    if (!db) {
      console.log('DBSyncProvider: db still initializing, wait to sync')
      return
    }

    if (firebaseAuthIsLoading) {
      console.log('DBSyncProvider: firebase auth is still loading, just wait longer')
      return
    }

    if (!firebaseUserId) {
      console.log('DBSyncProvider: firebase is done loading, but we dont have a userId, so nothing to sync yet')
      setSyncIsLoading(false)
      return
    }

    if (replicationStatesRef.current.length < 1) {
      console.log('DBSyncProvider: db and firestore are initialized, so initialize sync')
      initializeSync(db, firestore, syncEnabled, fronteggAuthState.fronteggAuth).then(async (states) => {
        replicationStatesRef.current = states

        if (syncEnabled) {
          replicationStatesRef.current.forEach(async (state) => await state.start())

          const lastSyncDuration = 1000 * 60
          await Promise.all(
            states.map((state) =>
              firstValueFrom(
                state.collection
                  .getLocal$('last-in-sync')
                  .pipe(filter((d: any) => d.get('time') > Date.now() - lastSyncDuration)),
              ),
            ),
          )
        }

        console.log('DBSyncProvider: sync is complete')
        setSyncIsLoading(false)
        setInitialSyncComplete(true)
      })
    } else if (syncEnabled) {
      console.log('DBSyncProvider: replication states initialized, so start them')
      replicationStatesRef.current.forEach(async (state) => await state.start())
    } else {
      console.log('DBSyncProvider: sync is initialized, but sync is disabled so cancel them')
      replicationStatesRef.current.forEach((state) => state.cancel())
    }
  }, [db, firebaseAuthIsLoading, firebaseUserId, firestore, syncEnabled, setCookie, fronteggAuthState])

  console.log(
    `DBSyncProvider: rendering syncIsLoading=${syncIsLoading} syncEnabled=${syncEnabled} initialSyncComplete=${initialSyncComplete}`,
  )

  const value = useMemo(() => {
    return {
      syncEnabled,
      syncIsLoading,
      initialSyncComplete,
      setSyncEnabled: (enabled: boolean) => {
        console.log(`DBSyncProvider: setSyncEnabled(${enabled})`)
        setSyncEnabled(enabled)
        setCookie('syncEnabled', enabled.toString(), { path: '/' })
      },
    }
  }, [syncEnabled, syncIsLoading, initialSyncComplete, setCookie])

  return <DBSyncContext.Provider value={value}>{children}</DBSyncContext.Provider>
}

export const useDBSyncState = () => {
  return useContext(DBSyncContext)
}
