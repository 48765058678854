import { Box, Typography, useTheme, useMediaQuery, Button } from '@mui/material'
import {
  DataGridPremium,
  gridClasses,
  GridColDef,
  GridPagination,
  gridPaginationModelSelector,
  gridRowCountSelector,
  GridSlotsComponent,
  GridSlotsComponentsProps,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium'
import NoRowsOverlay from '../lib/NoRowsOverlay'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Account } from '@/lib/schemas/AccountSchema'
import { useState, useEffect } from 'react'
import { useAccountMembersCollection, useAccountsCollection } from '../app/DBProvider'
import { RxDocument } from 'rxdb'
import { useCurrentOrg, useUser } from '../app/UserProvider'
import { AccountMember } from '@/lib/schemas/AccountMemberSchema'

function NoAccountsRowsOverlay() {
  return <NoRowsOverlay message="No accounts" />
}

export default function AccountsTable({
  columns,
  isOther = false,
}: Readonly<{
  isOther?: boolean
  columns: GridColDef<RxDocument<Account>>[]
}>) {
  const currentUser = useUser()
  const accountsCollection = useAccountsCollection()
  const accountMembersCollection = useAccountMembersCollection()
  const [searchParams] = useSearchParams()
  const selectedStatus = searchParams.get('status') ?? ''
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const currentOrg = useCurrentOrg()

  const [isFetchingMemberships, setIsFetchingMemberships] = useState(true)
  const [membershipQueryResult, setMembershipQueryResult] = useState<RxDocument<AccountMember>[]>([])
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(true)
  const [accounts, setAccounts] = useState<RxDocument<Account>[]>([])

  useEffect(() => {
    const fetchMemberships = async () => {
      const query = accountMembersCollection
        .find()
        .where('orgId')
        .equals(currentOrg.id)
        .where('userId')
        .equals(currentUser.id)
      const membershipQueryResult = await query.exec()
      setMembershipQueryResult(membershipQueryResult)
      setIsFetchingMemberships(false)
    }

    fetchMemberships()
  }, [accountMembersCollection, currentOrg.id, currentUser.id])

  useEffect(() => {
    if (isFetchingMemberships || !membershipQueryResult) return

    const fetchAccounts = async () => {
      const membershipAccountIds = membershipQueryResult.map((membership) => membership.accountId)
      let query = accountsCollection
        .find()
        .where('orgId')
        .equals(currentOrg.id)
        .where('id')
        .nin(membershipAccountIds)
        .sort('name')

      if (selectedStatus) {
        query = query.where('status').equals(selectedStatus)
      }

      const accountQueryResult = await query.exec()

      setAccounts(accountQueryResult)
      setIsFetchingAccounts(false)
    }

    const fetchMembershipAccounts = async () => {
      const populatedAccounts = await Promise.all(
        membershipQueryResult.map(async (membership) => {
          const account = await membership.populate('accountId')
          return account
        }),
      )
      setAccounts(populatedAccounts)
      setIsFetchingAccounts(false)
    }

    if (isOther) {
      fetchAccounts()
    } else {
      fetchMembershipAccounts()
    }
  }, [accountsCollection, selectedStatus, currentOrg.id, membershipQueryResult, isOther, isFetchingMemberships])

  const handleRowClick = (params: any) => {
    if (isPhone) {
      navigate(`/accounts/${params.row.id}`)
    }
  }

  const slots: Partial<GridSlotsComponent> = {
    noRowsOverlay: NoAccountsRowsOverlay,
    toolbar: CustomToolbar,
    columnHeaders: isPhone ? () => null : undefined, // Hide column headers only on small screens
    pagination: CustomPagination,
  }

  const slotProps: GridSlotsComponentsProps = {
    toolbar: {
      showQuickFilter: true,
    },
  }

  if (isFetchingAccounts || !accounts) return <></>

  return (
    <DataGridPremium
      columns={columns}
      rows={accounts} // Use accounts state variable
      getRowId={(row) => row.id} // Add getRowId function
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' as GridSortDirection }], // Set initial sort
        },
        pagination: { paginationModel: { pageSize: 25 } },
      }}
      slots={slots}
      slotProps={slotProps}
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
      disableColumnResize
      onRowClick={handleRowClick} // Add onRowClick handler
      getRowHeight={() => 'auto'} // Set row height to auto
      pagination
      pageSizeOptions={[25, 50, 100]}
      sx={{
        // this removes the drag handles
        [`& .${gridClasses.columnSeparator}`]: {
          [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
            display: 'none',
          },
        },
        // this removes the cell focus
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}`]: {
          backgroundImage: 'var(--Paper-overlay)',
        },
        border: 'none',
        [`& .${gridClasses.cell}`]: {
          display: 'flex',
          alignItems: 'center', // Center values vertically
          paddingTop: '16px', // Add padding to the top of each row
          paddingBottom: '16px', // Add padding to the bottom of each row
        },
        [`& .${gridClasses.row}`]: {
          cursor: !isPhone ? 'default' : 'pointer', // Conditionally add cursor pointer to rows
        },
        ...(isPhone && {
          [`& .MuiDataGrid-footerContainer`]: {
            borderTop: 'none', // Remove top border
          },
          marginLeft: -3, // Add negative left padding for small screens
          marginRight: -3, // Add negative right padding for small screens
          [`& .${gridClasses.cell}`]: {
            paddingX: 3, // Add positive right padding for small screens
            paddingY: 2, // Add positive top and bottom padding for small screens
          },
        }),
      }}
    />
  )
}

function CustomToolbar() {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <GridToolbarContainer
      sx={{
        ...(isPhone && {
          paddingLeft: 3, // Add positive left padding for small screens
          paddingRight: 3, // Add positive right padding for small screens
        }),
      }}
    >
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  )
}

function CustomPagination(props: any) {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const apiRef = useGridApiContext()
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector)
  const rowCount: number = useGridSelector(apiRef, gridRowCountSelector)

  const handleLoadMore = () => {
    apiRef.current.setPageSize(paginationModel.pageSize + 25)
  }

  if (isPhone) {
    if (paginationModel.pageSize >= rowCount) {
      return null
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          my: 2,
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          width: 'fit-content',
          backgroundColor: 'white', // Ensure background color to avoid overlap issues
          zIndex: 1, // Ensure it stays above other content
        }}
      >
        <Button variant="outlined" color="primary" onClick={handleLoadMore} sx={{ textAlign: 'center' }}>
          Load More...
        </Button>
        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
          Showing {paginationModel.pageSize} of {rowCount}
        </Typography>
      </Box>
    )
  } else {
    return <GridPagination {...props} />
  }
}
