import { useRxQuery } from 'rxdb-hooks'
import { useDevicesCollection } from '../app/DBProvider'
import { Floor } from '@/lib/schemas/FloorSchema'
import { Device } from '@/lib/schemas/DeviceSchema'
import { Table, TableBody, TableCell, TableHead, TableRow, Link } from '@mui/material'

export default function FloorDevicesTab({ floor }: Readonly<{ floor: Floor }>) {
  const devicesCollection = useDevicesCollection()

  const { result: devices, isFetching } = useRxQuery(devicesCollection.find({ selector: { floorId: floor.id } }), {
    json: true,
  })

  if (isFetching) return <></>

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Devices</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {devices?.map((device: Device) => {
          return (
            <TableRow key={device.id}>
              <TableCell>
                <Link href={`devices/${device.id}`}>{device.id}</Link>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
