import React, { useState } from 'react'
import { Modal, Box, Typography, TextField, Button, IconButton, useMediaQuery, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Account, UnitOfMeasurement } from '@/lib/schemas/AccountSchema'
import { useAccountsCollection } from '../app/DBProvider'
import * as uuid from 'uuid'
import { useCurrentOrg } from '../app/UserProvider'

interface NewAccountModalProps {
  open: boolean
  onClose: () => void
  onCreate: (account: any) => void
}

const NewAccountModal: React.FC<NewAccountModalProps> = ({ open, onClose, onCreate }) => {
  const currentOrg = useCurrentOrg()
  const [accountName, setAccountName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const accountsCollection = useAccountsCollection()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleCreate = async () => {
    const newAccount: Account = {
      id: uuid.v4(),
      orgId: currentOrg.id,
      name: accountName,
      email,
      phoneNumber,
      isActive: true,
      createdDate: new Date().toISOString(),
      createdByUserId: currentOrg.id,
      updatedDate: new Date().toISOString(),
      updatedByUserId: currentOrg.id,
      settings: {
        isLocalSaveEnabled: false,
        isCablingEnabled: false,
        defaultAssignedToUserId: '',
        isUnlimitedPlansInReportsEnabled: false,
        isLiveSystemAccessEnabled: false,
        isTicketingEnabled: false,
        isDeviceActionEnabled: false,
        unitOfMeasurement: UnitOfMeasurement.feet,
      },
      _deleted: false,
    }
    await accountsCollection.insert(newAccount)
    onCreate(newAccount)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle, ...(isSmallScreen && modalFullScreenStyle) }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" component="h2">
            New Account
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField
          label="Account Name"
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button onClick={onClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleCreate}>
            Create Account
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

const modalFullScreenStyle = {
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  transform: 'none',
  display: 'flex',
  flexDirection: 'column',
  p: 2,
}

export default NewAccountModal
