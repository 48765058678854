import { createDb, initializeDb, SiteOwlDatabase } from '@/lib/rxdb'
import { AccountMember } from '@/lib/schemas/AccountMemberSchema'
import { Account } from '@/lib/schemas/AccountSchema'
import { Building } from '@/lib/schemas/BuildingSchema'
import { DeviceImage } from '@/lib/schemas/DeviceImageSchema'
import { Device } from '@/lib/schemas/DeviceSchema'
import { DeviceVersion } from '@/lib/schemas/DeviceVersionSchema'
import { EquipmentTypeCategory } from '@/lib/schemas/EquipmentTypeCategorySchema'
import { EquipmentType } from '@/lib/schemas/EquipmentTypeSchema'
import { FavoriteDevice } from '@/lib/schemas/FavoriteDeviceSchema'
import { Favorite } from '@/lib/schemas/FavoriteSchema'
import { FavoriteTask } from '@/lib/schemas/FavoriteTaskSchema'
import { Floor } from '@/lib/schemas/FloorSchema'
import { Location } from '@/lib/schemas/LocationSchema'
import { OrgMember } from '@/lib/schemas/OrgMemberSchema'
import { Org } from '@/lib/schemas/OrgSchema'
import { ProjectFileAttachment } from '@/lib/schemas/ProjectFileAttachmentSchema'
import { ProjectFloor } from '@/lib/schemas/ProjectFloorSchema'
import { ProjectMember } from '@/lib/schemas/ProjectMemberSchema'
import { Project } from '@/lib/schemas/ProjectSchema'
import { ServiceTicketComment } from '@/lib/schemas/ServiceTicketCommentSchema'
import { ServiceTicketFileAttachment } from '@/lib/schemas/ServiceTicketFileAttachmentSchema'
import { ServiceTicket } from '@/lib/schemas/ServiceTicketSchema'
import { ServiceTicketWatcher } from '@/lib/schemas/ServiceTicketWatcherSchema'
import { SiteMember } from '@/lib/schemas/SiteMemberSchema'
import { Site } from '@/lib/schemas/SiteSchema'
import { TaskImage } from '@/lib/schemas/TaskImageSchema'
import { Task } from '@/lib/schemas/TaskSchema'
import { TaskType } from '@/lib/schemas/TaskTypeSchema'
import { UserAccountPreferences } from '@/lib/schemas/UserAccountPreferencesSchema'
import { UserDeviceToken } from '@/lib/schemas/UserDeviceTokenSchema'
import { UserFloorPreferences } from '@/lib/schemas/UserFloorPreferencesSchema'
import { User } from '@/lib/schemas/UserSchema'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { RxCollection } from 'rxdb'

type DBState = {
  db?: SiteOwlDatabase
  dbIsReady: boolean
}

export const DBContext = createContext<DBState>({ db: undefined, dbIsReady: false })

export default function DBProvider({
  defaultDb = undefined,
  defaultDbIsReady = false,
  children,
}: Readonly<{
  defaultDb?: SiteOwlDatabase
  defaultDbIsReady?: boolean
  children: React.ReactNode
}>) {
  const [db, setDb] = useState<SiteOwlDatabase | undefined>(defaultDb)
  const [dbIsReady, setDbIsReady] = useState(defaultDbIsReady)

  useEffect(() => {
    if (db) return

    console.log('DBProvider: create the rxdb')
    createDb().then(async (db) => {
      console.log('DBProvider: db is created, so now initialize it')
      await initializeDb(db)
      setDb(db)
      setDbIsReady(true)
    })
  }, [db])

  console.log(`DBProvider: rendering db=${db} dbIsReady=${dbIsReady}`)

  const value = useMemo(() => {
    return { db, dbIsReady }
  }, [db, dbIsReady])

  return <DBContext.Provider value={value}>{children}</DBContext.Provider>
}

export const useDBState = () => {
  return useContext(DBContext)
}

export const useDB = (): SiteOwlDatabase => {
  const db = useContext(DBContext).db
  if (!db) throw new Error('database not configured')
  return db
}

export const useAccountsCollection = (): RxCollection<Account> => {
  const db = useDB()
  return db.accounts
}

export const useUsersCollection = (): RxCollection<User> => {
  const db = useDB()
  return db.users
}

export const useOrgsCollection = (): RxCollection<Org> => {
  const db = useDB()
  return db.orgs
}

export const useOrgMembersCollection = (): RxCollection<OrgMember> => {
  const db = useDB()
  return db.org_members
}

export const useBuildingsCollection = (): RxCollection<Building> => {
  const db = useDB()
  return db.buildings
}

export const useAccountMembersCollection = (): RxCollection<AccountMember> => {
  const db = useDB()
  return db.account_members
}

export const useDeviceImagesCollection = (): RxCollection<DeviceImage> => {
  const db = useDB()
  return db.device_images
}

export const useDevicesCollection = (): RxCollection<Device> => {
  const db = useDB()
  return db.devices
}

export const useDeviceVersionsCollection = (): RxCollection<DeviceVersion> => {
  const db = useDB()
  return db.device_versions
}

export const useEquipmentTypeCategoriesCollection = (): RxCollection<EquipmentTypeCategory> => {
  const db = useDB()
  return db.equipment_type_categories
}

export const useEquipmentTypesCollection = (): RxCollection<EquipmentType> => {
  const db = useDB()
  return db.equipment_types
}

export const useFavoriteDevicesCollection = (): RxCollection<FavoriteDevice> => {
  const db = useDB()
  return db.favorite_devices
}

export const useFavoriteTasksCollection = (): RxCollection<FavoriteTask> => {
  const db = useDB()
  return db.favorite_tasks
}

export const useFavoritesCollection = (): RxCollection<Favorite> => {
  const db = useDB()
  return db.favorites
}

export const useFloorsCollection = (): RxCollection<Floor> => {
  const db = useDB()
  return db.floors
}

export const useLocationsCollection = (): RxCollection<Location> => {
  const db = useDB()
  return db.locations
}

export const useProjectFileAttachmentsCollection = (): RxCollection<ProjectFileAttachment> => {
  const db = useDB()
  return db.project_files
}

export const useProjectFloorsCollection = (): RxCollection<ProjectFloor> => {
  const db = useDB()
  return db.project_floors
}

export const useProjectMembersCollection = (): RxCollection<ProjectMember> => {
  const db = useDB()
  return db.project_members
}

export const useProjectsCollection = (): RxCollection<Project> => {
  const db = useDB()
  return db.projects
}

export const useServiceTicketCommentsCollection = (): RxCollection<ServiceTicketComment> => {
  const db = useDB()
  return db.service_ticket_comments
}

export const useServiceTicketFileAttachmentsCollection = (): RxCollection<ServiceTicketFileAttachment> => {
  const db = useDB()
  return db.service_ticket_file_attachments
}

export const useServiceTicketWatchersCollection = (): RxCollection<ServiceTicketWatcher> => {
  const db = useDB()
  return db.service_ticket_watchers
}

export const useServiceTicketsCollection = (): RxCollection<ServiceTicket> => {
  const db = useDB()
  return db.service_tickets
}

export const useSiteMembersCollection = (): RxCollection<SiteMember> => {
  const db = useDB()
  return db.site_members
}

export const useSitesCollection = (): RxCollection<Site> => {
  const db = useDB()
  return db.sites
}

export const useTaskImagesCollection = (): RxCollection<TaskImage> => {
  const db = useDB()
  return db.task_images
}

export const useTaskTypesCollection = (): RxCollection<TaskType> => {
  const db = useDB()
  return db.task_types
}

export const useTasksCollection = (): RxCollection<Task> => {
  const db = useDB()
  return db.tasks
}

export const useUserAccountPreferencesCollection = (): RxCollection<UserAccountPreferences> => {
  const db = useDB()
  return db.user_account_preferences
}

export const useUserDeviceTokensCollection = (): RxCollection<UserDeviceToken> => {
  const db = useDB()
  return db.user_device_tokens
}

export const useUserFloorPreferencesCollection = (): RxCollection<UserFloorPreferences> => {
  const db = useDB()
  return db.user_floor_preferences
}
