import { Box, Typography, Link } from '@mui/material'
import AccountServiceTicketsTable from '../account/AccountServiceTicketsTable '
import { GridColDef } from '@mui/x-data-grid-premium'
import { RxDocument } from 'rxdb'
import { ServiceTicket } from '@/lib/schemas/ServiceTicketSchema'
import Breadcrumbs from '../layout/Breadcrumbs'

export default function OrgServiceTicketsPage() {
  // const isTablet = useMediaQuery(theme.breakpoints.up('md'))

  const formatStatus = (status: string) => {
    return status
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const formatDueDate = (dueDate: string | undefined) => {
    if (!dueDate) return ''
    const date = new Date(dueDate)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }

  const columns: GridColDef<RxDocument<ServiceTicket>>[] = [
    {
      field: 'id',
      headerName: 'Ticket #',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: '100%' }}>
          <Link href={`/accounts/${params.row.accountId}/service-tickets/${params.row.id}`} variant="body1">
            {params.row.id}
          </Link>
          <Box sx={{ display: { xs: 'block', sm: 'none' }, mt: 1 }}>
            <Link href={`/accounts/${params.row.accountId}/service-tickets/${params.row.id}`} variant="body2">
              {params.row.subject}
            </Link>
          </Box>
        </Box>
      ),
    },
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 2,
      renderCell: (params) => (
        <Link href={`/accounts/${params.row.accountId}/service-tickets/${params.row.id}`} variant="body2">
          {params.row.subject}
        </Link>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <Typography variant="body2">{formatStatus(params.row.status)}</Typography>,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 1,
      renderCell: (params) => <Typography variant="body2">{formatDueDate(params.row.dueDate)}</Typography>,
    },
    {
      field: 'site',
      headerName: 'Site',
      flex: 1,
      renderCell: (params) => {
        const site = (params.row as any).site
        return site ? (
          <Link href={`/accounts/${params.row.accountId}/sites/${site.id}`} variant="body2">
            {site.name}
          </Link>
        ) : null
      },
    },
    {
      field: 'building',
      headerName: 'Building',
      flex: 1,
      renderCell: (params) => {
        const building = (params.row as any).building
        return building ? (
          <Link
            href={`/accounts/${params.row.accountId}/sites/${building.siteId}/buildings/${building.id}`}
            variant="body2"
          >
            {building.name}
          </Link>
        ) : null
      },
    },
    {
      field: 'plan',
      headerName: 'Plan',
      flex: 1,
      renderCell: (params) => {
        const floor = (params.row as any).floor
        return floor ? (
          <Link
            href={`/accounts/${params.row.accountId}/sites/${floor.siteId}/buildings/${floor.buildingId}/floors/${floor.id}`}
            variant="body2"
          >
            {floor.name}
          </Link>
        ) : null
      },
    },
  ]

  return (
    <>
      <Breadcrumbs />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h5">Service Tickets</Typography>
          <Typography variant="body2" color="textSecondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
            Service Tickets shows all of the Service Tickets that are associated with the Live Site.{' '}
          </Typography>
        </Box>
      </Box>
      <AccountServiceTicketsTable columns={columns} />
    </>
  )
}
