import { useRxQuery } from 'rxdb-hooks'
import { useFavoriteDevicesCollection } from '../app/DBProvider'
import { Favorite } from '@/lib/schemas/FavoriteSchema'
import { Table, TableBody, TableCell, TableHead, TableRow, Link } from '@mui/material'

export default function FavoriteDevicesTab({ favorite }: Readonly<{ favorite: Favorite }>) {
  const favoriteDevicesCollection = useFavoriteDevicesCollection()

  const { result: favoriteDevices, isFetching } = useRxQuery(
    favoriteDevicesCollection.find({ selector: { favoriteId: favorite.id } }),
    {
      json: true,
    },
  )

  if (isFetching) return <></>

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Favorite Device</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {favoriteDevices?.map((favoriteDevice) => {
          return (
            <TableRow key={favoriteDevice.id}>
              <TableCell>
                <Link href={`${favoriteDevice.id}`}>{favoriteDevice.name}</Link>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
