import NotFoundPage from '@/components/NotFoundPage'
import { Tab, Tabs, Box, Link, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useFavoriteTasksCollection } from '../app/DBProvider'
import FavoriteTaskInfoTab from './FavoriteTaskInfoTab'

const tabValues = ['info']

export default function FavoriteTaskPage() {
  const { favoriteTaskId, tabId = 'info' } = useParams()
  const tabIndex = tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0
  const favoriteTasksCollection = useFavoriteTasksCollection()

  const { result: favoriteTasks, isFetching } = useRxQuery(favoriteTasksCollection.findOne(favoriteTaskId), {
    json: true,
  })

  if (isFetching) return <></>

  const favoriteTask = favoriteTasks?.[0]
  if (!favoriteTask) return <NotFoundPage />

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {favoriteTask.name}
      </Typography>
      <Tabs value={tabIndex} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="Info" component={Link} href={`/favorite-tasks/${favoriteTaskId}`} />
      </Tabs>
      <Box sx={{ p: 2 }}>{tabIndex === 0 && <FavoriteTaskInfoTab favoriteTask={favoriteTask} />}</Box>
    </>
  )
}
