import React, { useState } from 'react'
import { Modal, Box, Typography, TextField, Button, MenuItem, IconButton, useMediaQuery, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Project, ProjectStage, ProjectStatus } from '@/lib/schemas/ProjectSchema'
import { useProjectsCollection } from '../app/DBProvider'
import * as uuid from 'uuid'
import { useCurrentOrg } from '../app/UserProvider'

interface NewProjectModalProps {
  open: boolean
  onClose: () => void
  onCreate: (project: any) => void
  accountId: string
}

const NewProjectModal: React.FC<NewProjectModalProps> = ({ open, onClose, onCreate, accountId }) => {
  const currentOrg = useCurrentOrg()
  const [projectName, setProjectName] = useState('')
  const [projectStage, setProjectStage] = useState<ProjectStage>(ProjectStage.design)
  const [startDate, setStartDate] = useState('')
  const [completionDate, setCompletionDate] = useState('')
  const [address, setAddress] = useState('')
  const [description, setDescription] = useState('')
  const projectsCollection = useProjectsCollection()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleCreate = async () => {
    const newProject: Project = {
      id: uuid.v4(),
      orgId: currentOrg.id,
      accountId,
      name: projectName,
      stage: projectStage,
      startDate,
      completionDate,
      address,
      description,
      createdDate: new Date().toISOString(),
      updatedDate: new Date().toISOString(),
      designStatus: ProjectStatus.open,
      installationStatus: ProjectStatus.open,
      isArchived: false,
      isPublished: false,
      _deleted: false,
    }
    await projectsCollection.insert(newProject)
    onCreate(newProject)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle, ...(isSmallScreen && modalFullScreenStyle) }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" component="h2">
            New Project
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField
          label="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          select
          label="Project Stage"
          value={projectStage}
          onChange={(e) => setProjectStage(e.target.value as ProjectStage)}
          fullWidth
          margin="normal"
        >
          {Object.values(ProjectStage).map((stage) => (
            <MenuItem key={stage} value={stage}>
              {stage}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Target Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
          margin="normal"
          slotProps={{ inputLabel: { shrink: true } }}
        />
        <TextField
          label="Target Completion Date"
          type="date"
          value={completionDate}
          onChange={(e) => setCompletionDate(e.target.value)}
          fullWidth
          margin="normal"
          slotProps={{ inputLabel: { shrink: true } }}
        />
        <TextField
          label="Main Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Project Notes"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button onClick={onClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleCreate}>
            Create Project
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

const modalFullScreenStyle = {
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  transform: 'none',
  display: 'flex',
  flexDirection: 'column',
  p: 2,
}

export default NewProjectModal
