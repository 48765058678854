import NotFoundPage from '@/components/NotFoundPage'
import { Tab, Tabs, Box, Link, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useDevicesCollection } from '../app/DBProvider'
import DeviceImagesTab from './DeviceImagesTab'
import DeviceInfoTab from './DeviceInfoTab'

const tabValues = ['info', 'images']

export default function DevicePage() {
  const { deviceId, tabId = 'info' } = useParams()
  const tabIndex = tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0
  const devicesCollection = useDevicesCollection()

  const { result: devices, isFetching } = useRxQuery(devicesCollection.findOne(deviceId), { json: true })

  if (isFetching) return <></>

  const device = devices?.[0]
  if (!device) return <NotFoundPage />

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {device.id}
      </Typography>
      <Tabs value={tabIndex} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="Info" component={Link} href={`/devices/${deviceId}`} />
        <Tab label="Images" component={Link} href={`/devices/${deviceId}/images`} />
      </Tabs>
      <Box sx={{ p: 2 }}>
        {tabIndex === 0 && <DeviceInfoTab device={device} />}
        {tabIndex === 1 && <DeviceImagesTab device={device} />}
      </Box>
    </>
  )
}
