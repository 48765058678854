import { Box, Typography, useTheme, useMediaQuery, Button, Link, IconButton, Menu, MenuItem } from '@mui/material'
import {
  DataGridPremium,
  gridClasses,
  GridColDef,
  GridPagination,
  gridPaginationModelSelector,
  gridRowCountSelector,
  GridSlotsComponent,
  GridSlotsComponentsProps,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium'
import NoRowsOverlay from '../lib/NoRowsOverlay'
import { useNavigate, useParams } from 'react-router-dom'
import { Building } from '@/lib/schemas/BuildingSchema'
import { useState, useMemo, useEffect } from 'react'
import { useRxQuery } from 'rxdb-hooks'
import { useBuildingsCollection } from '../app/DBProvider'
import { RxDocument } from 'rxdb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons'
import NewBuildingModal from './NewBuildingModal'

function NoBuildingsOverlay() {
  return <NoRowsOverlay message="No buildings" />
}

export default function SiteBuildingsTab() {
  const accountId = useParams<{ accountId: string }>().accountId as string
  const siteId = useParams<{ siteId: string }>().siteId as string
  const buildingsCollection = useBuildingsCollection()
  const [isPopulated, setIsPopulated] = useState(false)
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const isTablet = useMediaQuery(theme.breakpoints.up('md'))

  const rxQuery = useMemo(() => {
    const query = buildingsCollection.find().where('siteId').equals(siteId).sort('name')

    return query
  }, [buildingsCollection, siteId])

  const { result: buildings, isFetching } = useRxQuery(rxQuery)

  useEffect(() => {
    if (isFetching || !buildings) return
    const populateBuildings = async () => {
      //   for (const building of buildings) {
      //     const account = await building.populate('accountId')
      //     ;(building as any).account = account
      //   }
      setIsPopulated(true)
    }
    populateBuildings()
  }, [isFetching, buildings])

  const handleRowClick = (params: any) => {
    if (isPhone) {
      navigate(`/accounts/${params.row.accountId}/buildings/${params.row.id}`)
    }
  }

  const slots: Partial<GridSlotsComponent> = {
    noRowsOverlay: NoBuildingsOverlay,
    toolbar: CustomToolbar,
    columnHeaders: isPhone ? () => null : undefined, // Hide column headers only on small screens
    pagination: CustomPagination,
  }

  const slotProps: GridSlotsComponentsProps = {
    toolbar: {
      showQuickFilter: true,
    },
  }

  const columns: GridColDef<RxDocument<Building>>[] = [
    {
      field: 'name',
      headerName: 'Building Name',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: '100%' }}>
          <Link href={`/accounts/${accountId}/sites/${siteId}/buildings/${params.row.id}`} variant="body1">
            {params.row.name}
          </Link>
          <Box sx={{ display: { xs: 'block', sm: 'none' }, mt: 1 }}>
            <Typography variant="body2">6 plans | 38 devices | 78 % operational</Typography>
            <Box sx={{ width: '100%', display: 'flex', height: 10, mt: 1 }}>
              <Box sx={{ width: '13%', bgcolor: theme.palette.error.main, borderRadius: '5px 0 0 5px' }} />
              <Box sx={{ width: '45%', bgcolor: theme.palette.info.main }} />
              <Box sx={{ width: '42%', bgcolor: theme.palette.warning.main, borderRadius: '0 5px 5px 0' }} />
            </Box>
          </Box>
        </Box>
      ),
    },
  ]

  if (isTablet) {
    columns.push(
      {
        field: 'plans',
        headerName: 'Plans',
        flex: 1,
        renderCell: () => <Typography variant="body2">6</Typography>,
      },
      {
        field: 'devices',
        headerName: 'Devices',
        flex: 1,
        renderCell: () => <Typography variant="body2">48</Typography>,
      },
      {
        field: 'operational',
        headerName: '% Operational',
        flex: 1,
        renderCell: () => <Typography variant="body2">78%</Typography>,
      },
      {
        field: 'progress',
        headerName: '',
        flex: 1,
        renderCell: () => (
          <Box sx={{ width: '100%', display: 'flex', height: 10 }}>
            <Box sx={{ width: '13%', bgcolor: theme.palette.error.main, borderRadius: '5px 0 0 5px' }} />
            <Box sx={{ width: '45%', bgcolor: theme.palette.info.main }} />
            <Box sx={{ width: '42%', bgcolor: theme.palette.warning.main, borderRadius: '0 5px 5px 0' }} />
          </Box>
        ),
      },
    )
  }

  if (isFetching || !buildings || !isPopulated) return <></>

  return (
    <DataGridPremium
      columns={columns}
      rows={buildings} // Use buildings directly
      getRowId={(row) => row.id} // Add getRowId function
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' as GridSortDirection }], // Set initial sort
        },
        pagination: { paginationModel: { pageSize: 25 } },
      }}
      slots={slots}
      slotProps={slotProps}
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
      disableColumnResize
      onRowClick={handleRowClick} // Add onRowClick handler only for phone screens
      getRowHeight={() => 'auto'} // Set row height to auto
      pagination
      pageSizeOptions={[25, 50, 100]}
      sx={{
        // this removes the drag handles
        [`& .${gridClasses.columnSeparator}`]: {
          [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
            display: 'none',
          },
        },
        // this removes the cell focus
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}`]: {
          backgroundImage: 'var(--Paper-overlay)',
        },
        border: 'none',
        [`& .${gridClasses.cell}`]: {
          display: 'flex',
          alignItems: 'center', // Center values vertically
          paddingTop: '16px', // Add padding to the top of each row
          paddingBottom: '16px', // Add padding to the bottom of each row
        },
        [`& .${gridClasses.row}`]: {
          cursor: { xs: 'pointer', md: 'default' }, // Add cursor pointer to rows only on phone
        },
        ...(isPhone && {
          [`& .MuiDataGrid-footerContainer`]: {
            borderTop: 'none', // Remove top border
          },
          marginLeft: -3, // Add negative left padding for small screens
          marginRight: -3, // Add negative right padding for small screens
          [`& .${gridClasses.cell}`]: {
            paddingX: 3, // Add positive right padding for small screens
            paddingY: 2, // Add positive top and bottom padding for small screens
          },
        }),
      }}
    />
  )
}

function CustomToolbar() {
  const accountId = useParams<{ accountId: string }>().accountId as string
  const siteId = useParams<{ siteId: string }>().siteId as string
  const navigate = useNavigate()
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCreateBuilding = (building: any) => {
    // Navigate to the newly created building's page
    navigate(`/accounts/${building.accountId}/sites/${building.siteId}/buildings/${building.id}`)
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuNewBuildingClick = () => {
    handleMenuClose()
    handleOpenModal()
  }

  return (
    <>
      <GridToolbarContainer
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          ...(isPhone && {
            paddingLeft: 3, // Add positive left padding for small screens
            paddingRight: 3, // Add positive right padding for small screens
          }),
        }}
      >
        <GridToolbarQuickFilter />
        <Button
          size="small"
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={handleOpenModal}
          sx={{
            display: { xs: 'none', md: 'inline-flex' },
            whiteSpace: 'nowrap',
            borderColor: theme.palette.text.secondary,
            color: theme.palette.text.secondary,
          }}
        >
          New Building
        </Button>
        <Box sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
          <IconButton onClick={handleMenuOpen}>
            <FontAwesomeIcon icon={faEllipsisV} />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuNewBuildingClick}>New Building</MenuItem>
          </Menu>
        </Box>
      </GridToolbarContainer>
      <NewBuildingModal
        siteId={siteId}
        open={isModalOpen}
        onClose={handleCloseModal}
        onCreate={handleCreateBuilding}
        accountId={accountId}
      />
    </>
  )
}

function CustomPagination(props: any) {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const apiRef = useGridApiContext()
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector)
  const rowCount: number = useGridSelector(apiRef, gridRowCountSelector)

  const handleLoadMore = () => {
    apiRef.current.setPageSize(paginationModel.pageSize + 25)
  }

  if (isPhone) {
    if (paginationModel.pageSize >= rowCount) {
      return null
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          my: 2,
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          width: 'fit-content',
          backgroundColor: 'white', // Ensure background color to avoid overlap issues
          zIndex: 1, // Ensure it stays above other content
        }}
      >
        <Button variant="outlined" color="primary" onClick={handleLoadMore} sx={{ textAlign: 'center' }}>
          Load More...
        </Button>
        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
          Showing {paginationModel.pageSize} of {rowCount}
        </Typography>
      </Box>
    )
  } else {
    return <GridPagination {...props} />
  }
}
