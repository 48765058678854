import { Box, Typography, useTheme, useMediaQuery, Button } from '@mui/material'
import {
  DataGridPremium,
  gridClasses,
  GridColDef,
  GridPagination,
  gridPaginationModelSelector,
  gridRowCountSelector,
  GridSlotsComponent,
  GridSlotsComponentsProps,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium'
import NoRowsOverlay from './NoRowsOverlay'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Project, ProjectStage } from '@/lib/schemas/ProjectSchema'
import { useState, useMemo, useEffect } from 'react'
import { useRxQuery } from 'rxdb-hooks'
import { useProjectsCollection } from '../app/DBProvider'
import ProjectStatusFilter from '../project/ProjectStatusFilter'
import { RxDocument } from 'rxdb'
import { useCurrentOrg } from '../app/UserProvider'

export function NoProjectRowsOverlay() {
  return <NoRowsOverlay message="No projects" />
}

export default function ProjectsTable({
  stage,
  isArchived = false,
  columns,
}: Readonly<{
  stage: ProjectStage
  isArchived?: boolean
  columns: GridColDef<RxDocument<Project>>[]
}>) {
  const { accountId } = useParams<{ accountId: string }>()
  const projectsCollection = useProjectsCollection()
  const [isPopulated, setIsPopulated] = useState(false)
  const [searchParams] = useSearchParams()
  const selectedStatus = searchParams.get('status') ?? ''
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const currentOrg = useCurrentOrg()

  const rxQuery = useMemo(() => {
    let query = projectsCollection
      .find()
      .where('orgId')
      .equals(currentOrg.id)
      .where('stage')
      .equals(stage)
      .where('isArchived')
      .equals(isArchived)
      .sort('name')

    if (accountId) {
      query = query.where('accountId').equals(accountId)
    }

    if (selectedStatus) {
      query = query.where(stage === ProjectStage.design ? 'designStatus' : 'installationStatus').equals(selectedStatus)
    }

    return query
  }, [projectsCollection, accountId, selectedStatus, isArchived, stage, currentOrg.id])

  const { result: projects, isFetching } = useRxQuery(rxQuery)

  useEffect(() => {
    if (isFetching || !projects) return
    const populateProjects = async () => {
      for (const project of projects) {
        const userField = stage === ProjectStage.design ? 'designerUserId' : 'projectManagerUserId'
        const user = await project.populate(userField)
        if (stage === ProjectStage.design) {
          ;(project as any).designerUser = user
        } else {
          ;(project as any).projectManagerUser = user
        }
        const account = await project.populate('accountId')
        ;(project as any).account = account
      }
      setIsPopulated(true)
    }
    populateProjects()
  }, [isFetching, projects, stage])

  const handleRowClick = (params: any) => {
    if (isPhone) {
      navigate(`/accounts/${params.row.accountId}/projects/${params.row.id}`)
    }
  }

  const slots: Partial<GridSlotsComponent> = {
    noRowsOverlay: NoProjectRowsOverlay,
    toolbar: CustomToolbar,
    columnHeaders: isPhone ? () => null : undefined, // Hide column headers only on small screens
    pagination: CustomPagination,
  }

  const slotProps: GridSlotsComponentsProps = {
    toolbar: {
      showQuickFilter: true,
    },
  }

  if (isFetching || !projects || !isPopulated) return <></>

  return (
    <DataGridPremium
      columns={columns}
      rows={projects} // Use projects directly
      getRowId={(row) => row.id} // Add getRowId function
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' as GridSortDirection }], // Set initial sort
        },
        pagination: { paginationModel: { pageSize: 25 } },
      }}
      slots={slots}
      slotProps={slotProps}
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
      disableColumnResize
      onRowClick={handleRowClick} // Add onRowClick handler only for phone screens
      getRowHeight={() => 'auto'} // Set row height to auto
      pagination
      pageSizeOptions={[25, 50, 100]}
      sx={{
        // this removes the drag handles
        [`& .${gridClasses.columnSeparator}`]: {
          [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
            display: 'none',
          },
        },
        // this removes the cell focus
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}`]: {
          backgroundImage: 'var(--Paper-overlay)',
        },
        border: 'none',
        [`& .${gridClasses.cell}`]: {
          display: 'flex',
          alignItems: 'center', // Center values vertically
          paddingTop: '16px', // Add padding to the top of each row
          paddingBottom: '16px', // Add padding to the bottom of each row
        },
        [`& .${gridClasses.row}`]: {
          cursor: { xs: 'pointer', md: 'default' }, // Add cursor pointer to rows only on phone
        },
        ...(isPhone && {
          [`& .MuiDataGrid-footerContainer`]: {
            borderTop: 'none', // Remove top border
          },
          marginLeft: -3, // Add negative left padding for small screens
          marginRight: -3, // Add negative right padding for small screens
          [`& .${gridClasses.cell}`]: {
            paddingX: 3, // Add positive right padding for small screens
            paddingY: 2, // Add positive top and bottom padding for small screens
          },
        }),
      }}
    />
  )
}

function CustomToolbar() {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <GridToolbarContainer
      sx={{
        ...(isPhone && {
          paddingLeft: 3, // Add positive left padding for small screens
          paddingRight: 3, // Add positive right padding for small screens
        }),
      }}
    >
      <GridToolbarQuickFilter />
      <Box sx={{ mx: 1 }}>
        <ProjectStatusFilter />
      </Box>
    </GridToolbarContainer>
  )
}

function CustomPagination(props: any) {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const apiRef = useGridApiContext()
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector)
  const rowCount: number = useGridSelector(apiRef, gridRowCountSelector)

  const handleLoadMore = () => {
    apiRef.current.setPageSize(paginationModel.pageSize + 25)
  }

  if (isPhone) {
    if (paginationModel.pageSize >= rowCount) {
      return null
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          my: 2,
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          width: 'fit-content',
          backgroundColor: 'white', // Ensure background color to avoid overlap issues
          zIndex: 1, // Ensure it stays above other content
        }}
      >
        <Button variant="outlined" color="primary" onClick={handleLoadMore} sx={{ textAlign: 'center' }}>
          Load More...
        </Button>
        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
          Showing {paginationModel.pageSize} of {rowCount}
        </Typography>
      </Box>
    )
  } else {
    return <GridPagination {...props} />
  }
}
