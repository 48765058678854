import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  TextField,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Project } from '@/lib/schemas/ProjectSchema'

interface ProjectEditModalProps {
  open: boolean
  onClose: () => void
  project: Project
}

export default function ProjectEditModal({ open, onClose, project }: Readonly<ProjectEditModalProps>) {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const [formData, setFormData] = useState({ ...project })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSave = () => {
    // Handle save logic here
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isPhone}>
      <DialogTitle>
        Edit Project
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: 500 }}>
          <TextField label="Project Name" name="name" value={formData.name} onChange={handleChange} fullWidth />
          <TextField
            label="Custom Project ID"
            name="customProjectId"
            value={formData.customProjectId}
            onChange={handleChange}
            fullWidth
          />
          <TextField label="Address" name="address" value={formData.address} onChange={handleChange} fullWidth />
          <TextField
            label="Design Status"
            name="designStatus"
            value={formData.designStatus}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Designer"
            name="designerUserId"
            value={formData.designerUserId}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Project Manager"
            name="projectManagerUserId"
            value={formData.projectManagerUserId}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Start Date"
            name="startDate"
            type="date"
            value={formData.startDate}
            onChange={handleChange}
            fullWidth
            slotProps={{ inputLabel: { shrink: true } }}
          />
          <TextField
            label="Completion Date"
            name="completionDate"
            type="date"
            value={formData.completionDate}
            onChange={handleChange}
            fullWidth
            slotProps={{ inputLabel: { shrink: true } }}
          />
          <TextField
            label="Warranty Expiration Date"
            name="warrantyDate"
            type="date"
            value={formData.warrantyDate}
            onChange={handleChange}
            fullWidth
            slotProps={{ inputLabel: { shrink: true } }}
          />
          <TextField
            label="Created Date"
            name="createdDate"
            value={formData.createdDate}
            slotProps={{ input: { readOnly: true } }}
            fullWidth
          />
          <TextField
            label="Updated Date"
            name="updatedDate"
            value={formData.updatedDate}
            slotProps={{ input: { readOnly: true } }}
            fullWidth
          />
          <TextField
            label="Created By User"
            name="createdByUserId"
            value={formData.createdByUserId}
            slotProps={{ input: { readOnly: true } }}
            fullWidth
          />
          <TextField
            label="Updated By User"
            name="updatedByUserId"
            value={formData.updatedByUserId}
            slotProps={{ input: { readOnly: true } }}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
