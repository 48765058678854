import NotFoundPage from '@/components/NotFoundPage'
import { Tab, Tabs, Box, Link, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import { useFloorsCollection } from '../app/DBProvider'
import FloorDevicesTab from './FloorDevicesTab'
import FloorInfoTab from './FloorInfoTab'
import FloorLocationsTab from './FloorLocationsTab'
import FloorMapTab from './FloorMapTab'
import FloorTasksTab from './FloorTasksTab'
import Breadcrumbs from '../layout/Breadcrumbs'

const tabValues = ['info', 'locations', 'devices', 'tasks', 'map']

export default function FloorPage() {
  const floorsCollection = useFloorsCollection()
  const { accountId, siteId, buildingId, floorId, tabId = 'info' } = useParams()
  const tabIndex = tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0

  const { result: floors, isFetching } = useRxQuery(floorsCollection.findOne(floorId), { json: true })

  if (isFetching) return <></>

  const floor = floors?.[0]
  if (!floor) return <NotFoundPage />

  const baseUrl = `/accounts/${accountId}/sites/${siteId}/buildings/${buildingId}/floors/${floorId}`

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Breadcrumbs />
      <Typography variant="h4" gutterBottom>
        {floor.name}
      </Typography>
      <Tabs value={tabIndex} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="Info" component={Link} href={baseUrl} />
        <Tab label="Locations" component={Link} href={`${baseUrl}/locations`} />
        <Tab label="Devices" component={Link} href={`${baseUrl}/devices`} />
        <Tab label="Tasks" component={Link} href={`${baseUrl}/tasks`} />
        <Tab label="Map" component={Link} href={`${baseUrl}/map`} />
      </Tabs>
      <Box sx={{ p: 2 }}>
        {tabIndex === 0 && <FloorInfoTab floor={floor} />}
        {tabIndex === 1 && <FloorLocationsTab floor={floor} />}
        {tabIndex === 2 && <FloorDevicesTab floor={floor} />}
        {tabIndex === 3 && <FloorTasksTab floor={floor} />}
        {tabIndex === 4 && <FloorMapTab floor={floor} />}
      </Box>
    </Box>
  )
}
