import AccountProjectsPage from '../lib/ProjectsPage'
import { Project, ProjectStage } from '@/lib/schemas/ProjectSchema'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Box, Typography, Avatar, useMediaQuery, useTheme, Link } from '@mui/material'
import ProjectStatusChip from '../project/ProjectStatusChip'
import { RxDocument } from 'rxdb'
import { User } from '@/lib/schemas/UserSchema'

export default function AccountDesignProjectsPage() {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const columns: GridColDef<RxDocument<Project>>[] = [
    {
      field: 'name',
      headerName: 'Project',
      flex: 2,
      renderCell: (params) => {
        const project = params.row
        const user = (project as any).designerUser as User
        const account = (project as any).account
        return (
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
              <Box>
                <Link href={`/accounts/${account?.id}/projects/${project.id}`} variant="body1">
                  {params.value}
                </Link>
                <Typography variant="caption" color="textSecondary" sx={{ display: { xs: 'block', md: 'none' } }}>
                  4 Plans | 16 Devices | 0 Tasks | 0 Budgeted Hours
                </Typography>
              </Box>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <ProjectStatusChip status={params.row.designStatus} />
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' }, paddingTop: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar src={user?.profileImagePath} alt={user?.name} sx={{ width: 24, height: 24, mr: 1 }} />
                <Typography variant="body2" color="textSecondary">
                  {user?.name ?? 'Unknown'}
                </Typography>
              </Box>
            </Box>
          </Box>
        )
      },
    },
  ]

  if (isTablet) {
    columns.push(
      {
        field: 'plans',
        headerName: 'Plans',
        flex: 1,
        renderCell: () => <Typography>4</Typography>, // Hardcoded count for now
      },
      {
        field: 'devices',
        headerName: 'Devices',
        flex: 1,
        renderCell: () => <Typography>16</Typography>, // Hardcoded count for now
      },
      {
        field: 'tasks',
        headerName: 'Tasks',
        flex: 1,
        renderCell: () => <Typography>0</Typography>, // Hardcoded count for now
      },
      {
        field: 'budgetedHours',
        headerName: 'Budgeted Hours',
        flex: 1,
        renderCell: () => <Typography>0</Typography>, // Hardcoded count for now
      },
    )
  }

  if (isDesktop) {
    columns.push(
      {
        field: 'designerUserId',
        headerName: 'Designer',
        flex: 1,
        renderCell: (params) => {
          const project = params.row
          const user = (project as any).designerUser as User
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={user?.profileImagePath ?? ''} alt={user?.name} sx={{ width: 24, height: 24, mr: 1 }} />
              {user?.name ?? 'Unassigned'}
            </Box>
          )
        },
      },
      {
        field: 'designStatus',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => <ProjectStatusChip status={params.value} />,
      },
    )
  }

  return (
    <AccountProjectsPage
      stage={ProjectStage.design}
      title="Design Projects"
      description="Design Projects is used to define new Projects and to conduct assessments."
      buttonLabel="New Design"
      columns={columns}
    />
  )
}
