import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Divider,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  Theme,
  Switch,
  Link,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { GridColDef } from '@mui/x-data-grid-premium'
import { RxDocument } from 'rxdb'
import NewAccountModal from '../account/NewAccountModal'
import OrgAccountsTab from './OrgAccountsTab'
import { Account } from '@/lib/schemas/AccountSchema'

export default function OrgAccountsPage() {
  const isPhone = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const location = useLocation()
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const theme = useTheme()

  // Determine the tab index based on the current route
  const tabIndex = location.pathname.endsWith('/other-accounts') ? 1 : 0

  const columns: GridColDef<RxDocument<Account>>[] = [
    {
      field: 'name',
      headerName: 'Account Name',
      flex: 1,
      renderCell: (params) => (
        <div>
          <Box display="flex" flexDirection="column">
            <Link href={`/accounts/${params.row.id}`}>{params.row.name}</Link>
            {isPhone && (
              <Box display="flex" flexDirection="column" mt={1}>
                <Typography variant="caption" color="textSecondary">
                  12 users
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {params.row.email}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {params.row.phoneNumber}
                </Typography>
              </Box>
            )}
          </Box>
        </div>
      ),
    },
  ]

  if (!isPhone) {
    columns.push(
      { field: 'assignedUsers', headerName: 'Assigned Users', flex: 1 },
      {
        field: 'email',
        headerName: 'Email Address',
        flex: 1,
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone Number',
        flex: 1,
      },
      {
        field: 'includeInDailyReport',
        headerName: 'Include In Daily Report',
        flex: 1,
        renderCell: () => <Switch />,
      },
    )
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCreateAccount = (account: any) => {
    // Navigate to the newly created account's page
    navigate(`/accounts/${account.id}`)
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuNewAccountClick = () => {
    handleMenuClose()
    handleOpenModal()
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h5">Accounts</Typography>
          <Typography variant="body2" color="textSecondary" sx={{ display: { xs: 'none', sm: 'block' } }}>
            Accounts shows all Accounts associated with your Headquarters.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            size="small"
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={handleOpenModal}
            sx={{
              display: { xs: 'none', md: 'inline-flex' },
              whiteSpace: 'nowrap',
              borderColor: theme.palette.text.secondary,
              color: theme.palette.text.secondary,
            }}
          >
            Create Account
          </Button>
          <Box sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
            <IconButton onClick={handleMenuOpen}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleMenuNewAccountClick}>New Account</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      <>
        <Tabs
          value={tabIndex}
          aria-label="accounts tabs"
          sx={{
            minHeight: 32, // Adjust height
            '& .MuiTab-root': {
              minHeight: 32, // Reduce tab height
              fontSize: '0.75rem', // Smaller font size
              padding: '4px 8px', // Reduce padding
            },
          }}
        >
          <Tab label="Your Accounts" component={Link} href={`/accounts`} />
          <Tab label="Other Accounts" component={Link} href={`/other-accounts`} />
        </Tabs>
        <Divider sx={{ width: '100%', mb: 2 }} /> {/* Add solid line with margin bottom */}
        {tabIndex === 0 && <OrgAccountsTab columns={columns} />}
        {tabIndex === 1 && <OrgAccountsTab columns={columns} isOther={true} />}
      </>
      <NewAccountModal open={isModalOpen} onClose={handleCloseModal} onCreate={handleCreateAccount} />
    </>
  )
}
