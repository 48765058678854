import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import {
  DataGridPremium,
  gridClasses,
  GridColDef,
  GridPagination,
  gridPaginationModelSelector,
  gridRowCountSelector,
  GridSlotsComponent,
  GridSlotsComponentsProps,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium'
import NoRowsOverlay from '../lib/NoRowsOverlay'
import { useNavigate, useParams } from 'react-router-dom'
import { SiteMember } from '@/lib/schemas/SiteMemberSchema'
import { useState, useMemo, useEffect } from 'react'
import { useRxQuery } from 'rxdb-hooks'
import { useAccountMembersCollection, useSiteMembersCollection } from '../app/DBProvider'
import { RxDocument } from 'rxdb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import NewSiteMemberModal from './NewSiteMemberModal'
import { AccountMember } from '@/lib/schemas/AccountMemberSchema'

function NoSiteMembersOverlay() {
  return <NoRowsOverlay message="No members" />
}

export default function SiteMembersTab() {
  const siteId = useParams<{ siteId: string }>().siteId as string
  const membersCollection = useSiteMembersCollection()
  const accountMembersCollection = useAccountMembersCollection()
  const [isPopulated, setIsPopulated] = useState(false)
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedMember, setSelectedMember] = useState<SiteMember | null>(null)

  const rxQuery = useMemo(() => {
    const query = membersCollection.find().where('siteId').equals(siteId).sort('userId')

    return query
  }, [membersCollection, siteId])

  const { result: members, isFetching } = useRxQuery(rxQuery)

  useEffect(() => {
    if (isFetching || !members) return
    const populateSiteMembers = async () => {
      for (const member of members) {
        const user = await member.populate('userId')
        ;(member as any).user = user

        const accountMemberQuery = accountMembersCollection
          .findOne()
          .where('userId')
          .equals(user.id)
          .where('accountId')
          .equals(member.accountId)

        const accountMember = await accountMemberQuery.exec()
        ;(member as any).accountMember = accountMember
      }
      setIsPopulated(true)
    }
    populateSiteMembers()
  }, [accountMembersCollection, isFetching, members])

  const handleDeleteRow = (member: SiteMember) => {
    setSelectedMember(member)
    setDeleteDialogOpen(true)
  }

  const handleConfirmDelete = () => {
    if (selectedMember) {
      // Implement the delete functionality here
      console.log(`Delete member with id: ${selectedMember.id}`)
    }
    setDeleteDialogOpen(false)
    setSelectedMember(null)
  }

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
    setSelectedMember(null)
  }

  const slots: Partial<GridSlotsComponent> = {
    noRowsOverlay: NoSiteMembersOverlay,
    toolbar: CustomToolbar,
    columnHeaders: isPhone ? () => null : undefined, // Hide column headers only on small screens
    pagination: CustomPagination,
  }

  const slotProps: GridSlotsComponentsProps = {
    toolbar: {
      showQuickFilter: true,
    },
  }

  const columns: GridColDef<RxDocument<SiteMember>>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: '100%' }}>
          <Typography variant="body2">{(params.row as any).user.name}</Typography>
          <Box sx={{ display: { xs: 'block', sm: 'none' }, mt: 1 }}>
            <Typography variant="body2">{(params.row as any).user.email}</Typography>
            <Typography variant="body2">{(params.row as any).user.employer}</Typography>
            <Typography variant="body2">
              {((params.row as any).accountMember as AccountMember).roles
                .filter((role: string) => role.startsWith('live_'))
                .map((role: string) =>
                  role
                    .replace('live_', '')
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char) => char.toUpperCase()),
                )
                .join(', ')}
            </Typography>
            <Typography variant="body2">
              {new Date((params.row as any).user.lastActiveDate).toLocaleString('en-US', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              })}
            </Typography>
          </Box>
        </Box>
      ),
    },
  ]

  if (!isPhone) {
    columns.push(
      {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        renderCell: (params) => <Typography variant="body2">{(params.row as any).user.email}</Typography>,
      },
      {
        field: 'employer',
        headerName: 'Employer',
        flex: 1,
        renderCell: (params) => <Typography variant="body2">{(params.row as any).user.employer}</Typography>,
      },
      {
        field: 'role',
        headerName: 'Role',
        flex: 1,
        renderCell: (params) => {
          const roles = ((params.row as any).accountMember as AccountMember).roles
            .filter((role: string) => role.startsWith('live_'))
            .map((role: string) => role.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()))
            .join(', ')
          return <Typography variant="body2">{roles}</Typography>
        },
      },
      {
        field: 'lastActive',
        headerName: 'Last Active',
        flex: 1,
        renderCell: (params) => {
          const lastActiveDate = new Date((params.row as any).user.lastActiveDate)
          const formattedDate = lastActiveDate.toLocaleString('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          })
          return <Typography variant="body2">{formattedDate}</Typography>
        },
      },
    )
  }

  columns.push({
    field: 'delete',
    headerName: '',
    flex: 0,
    renderCell: (params) => (
      <IconButton onClick={() => handleDeleteRow(params.row)} size="small" sx={{ color: theme.palette.error.main }}>
        <FontAwesomeIcon icon={faTrash} />
      </IconButton>
    ),
  })

  if (isFetching || !members || !isPopulated) return <></>

  return (
    <>
      <DataGridPremium
        columns={columns}
        rows={members} // Use members directly
        getRowId={(row) => row.id} // Add getRowId function
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' as GridSortDirection }], // Set initial sort
          },
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        slots={slots}
        slotProps={slotProps}
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableColumnResize
        getRowHeight={() => 'auto'} // Set row height to auto
        pagination
        pageSizeOptions={[25, 50, 100]}
        sx={{
          // this removes the drag handles
          [`& .${gridClasses.columnSeparator}`]: {
            [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
              display: 'none',
            },
          },
          // this removes the cell focus
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}`]: {
            backgroundImage: 'var(--Paper-overlay)',
          },
          border: 'none',
          [`& .${gridClasses.cell}`]: {
            display: 'flex',
            alignItems: 'center', // Center values vertically
            paddingTop: '16px', // Add padding to the top of each row
            paddingBottom: '16px', // Add padding to the bottom of each row
          },
          [`& .${gridClasses.row}`]: {
            cursor: { xs: 'pointer', md: 'default' }, // Add cursor pointer to rows only on phone
          },
          ...(isPhone && {
            [`& .MuiDataGrid-footerContainer`]: {
              borderTop: 'none', // Remove top border
            },
            marginLeft: -3, // Add negative left padding for small screens
            marginRight: -3, // Add negative right padding for small screens
            [`& .${gridClasses.cell}`]: {
              paddingX: 3, // Add positive right padding for small screens
              paddingY: 2, // Add positive top and bottom padding for small screens
            },
          }),
        }}
      />
      <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Remove Member</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove {(selectedMember as any)?.user.name} from this site?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} variant="contained" color="error" autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function CustomToolbar() {
  const accountId = useParams<{ accountId: string }>().accountId as string
  const siteId = useParams<{ siteId: string }>().siteId as string
  const navigate = useNavigate()
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCreateSiteMember = (member: any) => {
    // Navigate to the newly created member's page
    navigate(`/accounts/${member.accountId}/sites/${member.siteId}/members/${member.id}`)
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuNewSiteMemberClick = () => {
    handleMenuClose()
    handleOpenModal()
  }

  return (
    <>
      <GridToolbarContainer
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          ...(isPhone && {
            paddingLeft: 3, // Add positive left padding for small screens
            paddingRight: 3, // Add positive right padding for small screens
          }),
        }}
      >
        <GridToolbarQuickFilter />
        <Button
          size="small"
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={handleOpenModal}
          sx={{
            display: { xs: 'none', md: 'inline-flex' },
            whiteSpace: 'nowrap',
            borderColor: theme.palette.text.secondary,
            color: theme.palette.text.secondary,
          }}
        >
          Add User
        </Button>
        <Box sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
          <IconButton onClick={handleMenuOpen}>
            <FontAwesomeIcon icon={faEllipsisV} />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuNewSiteMemberClick}>Add User</MenuItem>
          </Menu>
        </Box>
      </GridToolbarContainer>
      <NewSiteMemberModal
        siteId={siteId}
        open={isModalOpen}
        onClose={handleCloseModal}
        onCreate={handleCreateSiteMember}
        accountId={accountId}
      />
    </>
  )
}

function CustomPagination(props: any) {
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('md'))
  const apiRef = useGridApiContext()
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector)
  const rowCount: number = useGridSelector(apiRef, gridRowCountSelector)

  const handleLoadMore = () => {
    apiRef.current.setPageSize(paginationModel.pageSize + 25)
  }

  if (isPhone) {
    if (paginationModel.pageSize >= rowCount) {
      return null
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          my: 2,
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          width: 'fit-content',
          backgroundColor: 'white', // Ensure background color to avoid overlap issues
          zIndex: 1, // Ensure it stays above other content
        }}
      >
        <Button variant="outlined" color="primary" onClick={handleLoadMore} sx={{ textAlign: 'center' }}>
          Load More...
        </Button>
        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
          Showing {paginationModel.pageSize} of {rowCount}
        </Typography>
      </Box>
    )
  } else {
    return <GridPagination {...props} />
  }
}
