import NotFoundPage from '@/components/NotFoundPage'
import { Tab, Tabs, Box, Link, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { useRxQuery } from 'rxdb-hooks'
import FavoriteInfoTab from './FavoriteInfoTab'
import FavoriteFavoriteDevicesTab from './FavoriteDevicesTab'
import FavoriteFavoriteTasksTab from './FavoriteTasksTab'
import { useFavoritesCollection } from '../app/DBProvider'

const tabValues = ['info', 'devices', 'tasks']

export default function FavoritePage() {
  const { accountId, favoriteId, tabId = 'info' } = useParams()
  const tabIndex = tabValues.indexOf(tabId) !== -1 ? tabValues.indexOf(tabId) : 0
  const favoritesCollection = useFavoritesCollection()

  const { result: favorites, isFetching } = useRxQuery(favoritesCollection.findOne(favoriteId ?? ''), { json: true })

  if (isFetching) return <></>

  const favorite = favorites?.[0]
  if (!favorite) return <NotFoundPage />

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {favorite.name}
      </Typography>
      <Tabs value={tabIndex} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="Info" component={Link} href={`/accounts/${accountId}/favorites/${favoriteId}`} />
        <Tab label="Devices" component={Link} href={`/accounts/${accountId}/favorites/${favoriteId}/devices`} />
        <Tab label="Tasks" component={Link} href={`/accounts/${accountId}/favorites/${favoriteId}/tasks`} />
      </Tabs>
      <Box sx={{ p: 2 }}>
        {tabIndex === 0 && <FavoriteInfoTab favorite={favorite} />}
        {tabIndex === 1 && <FavoriteFavoriteDevicesTab favorite={favorite} />}
        {tabIndex === 2 && <FavoriteFavoriteTasksTab favorite={favorite} />}
      </Box>
    </>
  )
}
