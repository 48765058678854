import { Floor } from '@/lib/schemas/FloorSchema'
import { Table, TableBody, TableCell, TableHead, TableRow, Box } from '@mui/material'

export default function FloorInfoTab({ floor }: Readonly<{ floor: Floor }>) {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right">Field</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right">ID</TableCell>
            <TableCell>{floor.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Name</TableCell>
            <TableCell>{floor.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Building ID</TableCell>
            <TableCell>{floor.buildingId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Plan Image File Name</TableCell>
            <TableCell>{floor.planImageFileName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Plan Image Path</TableCell>
            <TableCell>{floor.planImagePath}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Order Number</TableCell>
            <TableCell>{floor.orderNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Width</TableCell>
            <TableCell>{floor.width}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Height</TableCell>
            <TableCell>{floor.height}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Floor Scale</TableCell>
            <TableCell>{floor.floorScale}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Is Archived</TableCell>
            <TableCell>{floor.isArchived}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {floor.planImagePath && (
        <Box sx={{ mt: 4 }}>
          <img
            src={`${import.meta.env.VITE_IMGIX_BASE_URL}/${floor.planImagePath}`}
            alt={floor.name}
            style={{ maxWidth: 500, border: '2px solid #2196f3' }}
          />
        </Box>
      )}
    </>
  )
}
