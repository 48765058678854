import { useRxQuery } from 'rxdb-hooks'
import { useLocationsCollection } from '../app/DBProvider'
import { Table, TableBody, TableCell, TableHead, TableRow, Link } from '@mui/material'
import { Floor } from '@/lib/schemas/FloorSchema'
import { Location } from '@/lib/schemas/LocationSchema'

export default function FloorLocationsTab({ floor }: Readonly<{ floor: Floor }>) {
  const locationsCollection = useLocationsCollection()

  const { result: locations, isFetching } = useRxQuery(locationsCollection.find({ selector: { floorId: floor.id } }), {
    json: true,
  })

  if (isFetching) return <></>

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Location</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {locations?.map((location: Location) => {
          return (
            <TableRow key={location.id}>
              <TableCell>
                <Link href={`${location.id}`}>{location.name}</Link>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
