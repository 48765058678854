import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar, { AppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Toolbar from '@mui/material/Toolbar'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import * as React from 'react'
import { Outlet, useLocation } from 'react-router'
import { useCookies } from 'react-cookie'
import UpdateButton from './UpdateButton'
import AccountDrawerMenu from './AccountDrawerMenu'
import OrgDrawerMenu from './OrgDrawerMenu'
import ProfileMenu from './ProfileMenu'
import OrgPicker from './OrgPicker'
import { useCurrentOrg } from '../app/UserProvider'
import Search from './Search'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: `calc(${drawerWidth}px + var(--safe-area-left))`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px + var(--safe-area-left))`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px + var(--safe-area-left))`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between', // Change to space-between to accommodate OrgPicker
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const DrawerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(1),
}))

interface MiniAppBarProps extends AppBarProps {
  open?: boolean
}

const MiniAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<MiniAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.background.default, // Change background color to adapt to theme
  borderBottom: `1px solid ${theme.palette.divider}`, // Change border color to adapt to theme
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `calc(${drawerWidth}px + var(--safe-area-left))`,
    width: `calc(100% - ${drawerWidth}px - var(--safe-area-left))`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const MiniDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function ResponsiveDrawer() {
  const org = useCurrentOrg()
  const [cookies, setCookie] = useCookies(['mobileOpen'])
  const [mobileOpen, setMobileOpen] = React.useState(cookies.mobileOpen)
  const [isClosing, setIsClosing] = React.useState(false)
  const location = useLocation()
  const accountIdMatch = /^\/accounts\/([^/]+)/.exec(location.pathname)
  const accountId = accountIdMatch ? accountIdMatch[1] : null

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false)
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      const newMobileOpen = !mobileOpen
      setMobileOpen(newMobileOpen)
      setCookie('mobileOpen', newMobileOpen, { path: '/' })
    }
  }

  const drawer = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: 'var(--safe-area-top)',
        paddingLeft: 'var(--safe-area-left)',
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <DrawerHeader>
          <OrgPicker /> {/* Add OrgPicker here */}
        </DrawerHeader>
        <Divider />
        <List>
          {(() => {
            if (accountId) {
              return (
                <AccountDrawerMenu
                  accountId={accountId}
                  mobileOpen={mobileOpen}
                  handleDrawerToggle={handleDrawerToggle}
                />
              )
            } else {
              return <OrgDrawerMenu mobileOpen={mobileOpen} orgId={org.id} handleDrawerToggle={handleDrawerToggle} />
            }
          })()}
        </List>
      </Box>
      <DrawerFooter>
        <IconButton onClick={handleDrawerToggle} sx={{ display: { xs: 'none', sm: 'block' } }}>
          {mobileOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerFooter>
    </Box>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: 'var(--safe-area-top)',
        paddingRight: 'var(--safe-area-right)',
        paddingBottom: 'var(--safe-area-bottom)',
        width: '100vw', // Add explicit width
        overflowX: 'hidden', // Prevent horizontal overflow
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        color="default"
        sx={{
          display: { xs: 'block', sm: 'none' },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          paddingTop: 'var(--safe-area-top)',
          height: 'var(--safe-area-top) + 65px',
          boxShadow: 'none', // Remove drop shadow
          borderBottom: `1px solid ${(theme: any) => theme.palette.divider}`, // Change border color to adapt to theme
          backgroundColor: 'background.default', // Change background color to adapt to theme
        }}
      >
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Search />
          <UpdateButton />
          <ProfileMenu />
        </Toolbar>
      </AppBar>
      <MiniAppBar
        position="fixed"
        open={mobileOpen}
        color="default"
        sx={{
          display: { xs: 'none', sm: 'block' },
          paddingTop: 'var(--safe-area-top)',
          height: 'calc(var(--safe-area-top) + 65px)',
          paddingLeft: 'var(--safe-area-left)',
          paddingRight: 'var(--safe-area-right)',
          boxShadow: 'none', // Remove drop shadow
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: 5,
              ...(mobileOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Search />
          <div style={{ flexGrow: 1 }} />
          <UpdateButton />
          <ProfileMenu />
        </Toolbar>
      </MiniAppBar>
      <SwipeableDrawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onOpen={handleDrawerToggle} // Function to handle the drawer opening
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </SwipeableDrawer>
      <MiniDrawer
        variant="permanent"
        open={mobileOpen}
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
      >
        {drawer}
      </MiniDrawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: '100%' }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  )
}
